import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import webservice from '../../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../../Service/crypto';

export const getcountresived = createAsyncThunk('appCalendar/files', async (data:any) => {

    const EncParams = AesEncrypt(data);
    const response = await fetch(webservice + 'api/Dashboard/getcountresived', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })
        .then((response) => response.json())
    // const TempData = AesDecrypt(response)

    return response;
})


export const getfilestatus = createAsyncThunk('appCalendar/files', async (data: any) => {

    const EncParams = AesEncrypt(data);
    const response = await fetch(webservice + 'api/Dashboard/getfilestatus', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })
        .then((response) => response.json())
    // const TempData = AesDecrypt(response)

    return response;
})


export const getapicount = createAsyncThunk('appCalendar/files', async (data: any) => {

    const EncParams = AesEncrypt(data);
    const response = await fetch(webservice + 'api/Dashboard/apicount', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })
        .then((response) => response.json())
    // const TempData = AesDecrypt(response)

    return response;
})



export const get_tat = createAsyncThunk('appCalendar/files', async (data: any) => {

    const EncParams = AesEncrypt(data);
    const response = await fetch(webservice + 'api/Dashboard/gettat', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })
        .then((response) => response.json())
    // const TempData = AesDecrypt(response)

    return response;
})   