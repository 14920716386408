import { Card, CardBody, CardHeader, Col, Label, Table } from "reactstrap";
import { H2, H3, H4 } from '../../../../AbstractElements';
import { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import webservice from "../../../../Service/webservice";
import websocket_webservice from "../../../../Service/websocket_webservice";
import Select, { SingleValue } from "react-select";
import { AesDecrypt, AesEncrypt } from "../../../../Service/crypto";
import classnames from "classnames";
import { useDispatch } from "react-redux";
const recentOrdersHeader = [
    "Patient Name", "Payer",'TAT', "Date", "Errors"
]

interface ClientOption {
    label: string;
    value: string;
    key: string;
}

interface IClientOption {
    label: string;
    value: string;
}
interface CompanyOption {
    label: string;
    value: string;
}

const RecentOrders = () => {
    const { control } = useForm();
    const [adminoption, setClient] = useState<ClientOption[]>([]);
    const [recentOrdersBody, setrecentOrdersBody] = useState<[]>([]);
    // const [ClientName, setClientName] = useState<string>('');
    const [selectedClient, setSelectedClient] = useState<string>('All');
    const [selectedClientId, setSelectedClientId] = useState<string>('ALL');
    const [clientOptions, setClientOptions] = useState<IClientOption[]>([]);
    const dispatch = useDispatch();

    const calculateTAT = (uploadedDate: string): number => {
        const uploadDateObj = new Date(uploadedDate); // Convert uploaded_date to Date object
        const currentDate = new Date(); // Get the current date
        const differenceInTime = currentDate.getTime() - uploadDateObj.getTime(); // Difference in milliseconds
        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24)); // Convert to days
        return differenceInDays;
    };

    const ViewAuditLog = async () => {
        const Username = localStorage.UserName;
        const Userid = localStorage.id;
        const Clientname = selectedClient;
        const Chartname = 'TAT Error'
        const Data = { Username, Clientname, Userid, Chartname };


        const EncParams = AesEncrypt(JSON.stringify(Data));
        const response = await fetch(webservice + 'api/Dashboard/auditlog', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then((response) => response.json());
        return response;
    };

    useEffect(() => {
        let clientid: string | null;

        if (localStorage.UserType === 'MOS') {
            clientid = selectedClientId;
        } else if (localStorage.UserType === 'Vendor') {
            clientid = selectedClientId;
        } else {
            clientid = localStorage.ClientId;
        }
        const fetchData = async (Id: any) => {
            const EncParams = AesEncrypt(Id);
            const response = await fetch(webservice + 'api/Dashboard/TATerror', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }).then((response) => response.json());

            const TempData = response.map((item: any) => ({
                ...item,
                TAT: calculateTAT(item.uploaded_date), // Add TAT field based on uploaded_date
            }));
            setrecentOrdersBody(TempData);
            return TempData;
        };

        fetchData(selectedClientId);
        ViewAuditLog()

        const socket = new WebSocket(`${websocket_webservice}ws/Dashboardpatient/`);
        // socket.onopen = () => console.log("WebSocket connected");
        // socket.onclose = () => console.log("WebSocket closed");
        // socket.onerror = (error) => console.error("WebSocket error:", error);

        socket.onmessage = () => {
            fetchData(selectedClientId);
        };

        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, [dispatch, selectedClientId, selectedClient]);

    const UserType = localStorage.UserType;
    const Id = localStorage.VendorId;
    const get_companyname = async () => {
        // Prepare the request body based on UserType
        let bodyData = {};
        if (UserType === 'MOS') {
            bodyData = { UserType }; // Send UserType only
        } else if (UserType === 'Vendor') {
            bodyData = { UserType, VendorId: Id }; // Send both UserType and VendorId
        }

        // Make the fetch request
        const response = await fetch(webservice + 'api/Dashboard/getclient', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(bodyData), // Send the request body
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    useEffect(() => {
        const fetchRoleId = async () => {
            try {
                const response = await get_companyname();
                const companyData = response.map((data: any) => ({
                    label: data.CompanyName,
                    value: data.id,
                }));

                // Sort the data alphabetically by label
                const sortedCompanyData = companyData.slice().sort((a: CompanyOption, b: CompanyOption) => a.label.localeCompare(b.label));
                // Add the 'All' option at the beginning of the list
                const allOption = { label: 'All', value: 'ALL' };
                const companyDataWithAll = [allOption, ...sortedCompanyData];

                // Set the client options state
                setClientOptions(companyDataWithAll);
            } catch (error) {
                console.error(error);
            }
        };

        // Fetch the initial data
        fetchRoleId();
        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            fetchRoleId();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, []);

    const handleSelectClientChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setSelectedClient(selectedOption.label);
            setSelectedClientId(selectedOption.value);
            // template()
        } else {
            setSelectedClient('');
            setSelectedClientId('ALL');
        }
    }


    return (

        <Card className="invoice-card" style={{ height: '95%' }}>
            <CardHeader>
                <div className="header-top">
                    <H2>TAT</H2>
                    
                    <Col xl={6}>
                        {(localStorage.UserType === 'MOS' || localStorage.UserType === 'Vendor') && (
                        <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Label className="form-label" style={{ fontSize: "110%" }}>
                                Select Client
                            </Label>
                            <Controller
                                name="client"
                                control={control} // control comes from react-hook-form
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isClearable
                                        options={clientOptions}
                                        classNamePrefix="select"
                                        placeholder="Client Names"
                                        className={classnames("form-control")}
                                        styles={{
                                            control: (styles) => ({
                                                ...styles,
                                                width: "100%",
                                                height: '100%',
                                                borderColor: "black",
                                            }),
                                            placeholder: (styles) => ({
                                                ...styles,
                                                textAlign: "center",
                                            }),
                                        }}
                                        value={selectedClient ? { value: selectedClientId, label: selectedClient } : null}
                                        onChange={(selectedOption) => {
                                            handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                            field.onChange(selectedOption); // Update the field's value
                                        }}
                                    />
                                )}
                            />
                            </div>
                        )}
                    </Col>
                   
                </div>
            </CardHeader>
            <CardBody className="transaction-card">
                <div className="table-responsive theme-scrollbar recent-wrapper">
                    <Table className="display order-wrapper" id="recent-order">
                        <thead>
                            <tr>
                                {recentOrdersHeader.map((data, index) => (
                                    <th key={index}>{data}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {recentOrdersBody.length > 0 ? (
                                recentOrdersBody.map((data: any, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex">
                                                <div className="flex-grow-1 ms-2">
                                                    <H4>{data.Patientname}</H4>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{data.Payer_name || 'N/A'}</td>
                                        <td>{data.TAT} days</td> {/* Display the TAT value */}
                                        <td>{data.uploaded_date}</td>
                                        <td className={`txt-danger`}>{data.error_message}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={recentOrdersHeader.length} style={{ textAlign: 'center' }}>
                                        No records to display
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>

    );
};

export default RecentOrders;
