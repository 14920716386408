import React from 'react'
import { Container } from 'reactstrap'
import { H3, H4 } from '../AbstractElements'
import { Link } from 'react-router-dom'
import { BackToHomePage } from '../utils/Constant'

const Maintenance = () => {
  return (
    <div className="page-wrapper">
      <div className="error-wrapper">
        <Container>
          <div className="maintenance-heading">
            <H3 className="headline">Access Denied</H3>
          </div>
          <H4 className="sub-content">
          Please contact your administrator for more information.
            <br /> Thank You For Patience
          </H4>
          <div>
            <Link className="btn btn-primary-gradien btn-lg text-light" to={`/dashboard`}>{BackToHomePage}</Link>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Maintenance