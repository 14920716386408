

// ** React Imports
import { Fragment, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Row, Col, Card, Label, Button, CardBody, CardHeader, FormGroup } from 'reactstrap'
import { ArrowLeftCircle } from 'react-feather'
import webservice from '../../../Service/webservice';
import * as Yup from "yup";
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto'
import React, { useState } from 'react';
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ErrorMessage, Field, Form, Formik } from 'formik'
import Select from 'react-select';
import Swal from 'sweetalert2'
import SVG from '../../../utils/CommonSvgIcon/SVG'

interface FormValues {
  MenuLabel: string;
  MenuType?: string;
  MenuUrl?: string;
  Menukey: string;
  MenuIcon: string;
  MenuOrder?: string;
  MenuShow: string;
  ParentMenu?: string;
  menuAction?: any;
  addUrl?:any,
  editUrl?:any,
}



const StatusOptions: { value: string; key: string; label: string }[] = [
  { value: 'yes', key: '0', label: 'Yes' },
  { value: 'no', key: '1', label: 'No' },
];



const fieldMapping: { [key: string]: string } = {
  MenuLabel: 'Menu Label',
  MenuType: 'Menu Type',
  Menukey: 'Menu Key',
  MenuIcon: 'Menu Icon',
  MenuShow: 'Menu Show',
  menuAction: 'Menu Action',
  addUrl: "Add Url",
  editUrl: "Edit Url",
};

const MandatoryFields = ['MenuLabel', 'MenuIcon', 'MenuShow', 'Menukey'];

const Username = localStorage.getItem('UserName') || '';

const EditMenu: React.FC = () => {
  const navigate = useNavigate();


  const [isSubmitting, setIsSubmitting] = useState(false);
  const [menuType, setMenuType] = useState<string>('Menu');
  const [res1, setRes1] = useState<any[]>([]);
  const [Id, setId] = useState<string>('');
  const [refId, setrefId] = useState<string>('');
  const [initialAudit, setinitialAudit] = useState<any>({});
  const [initialValues, setinitialValues] = useState<FormValues>({
    MenuLabel: '',
    MenuType: '', 
    MenuIcon: '',
    MenuOrder: '',
    MenuShow: '',
    MenuUrl: '',
    Menukey: '',
    ParentMenu: '',
    addUrl: '',
    editUrl: '',
    menuAction: {
      add: false,
      edit: false,
      delete: false,
      view: false,
    },
  });

  const [parentMenuOptions, setParentMenuOptions] = useState<{ value: string; label: string }[]>([]);
  const [menuAction, setMenuAction] = useState({ add: false, edit: false, delete: false, view: true });

  const handleMenuTypeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setMenuType(e.target.value);

  };



  const [IconOptions, setIconOptions] = useState<{ label: string, value: string }[]>([]);



  const fetchIconList = async () => {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/assets/svg/icon-sprite.svg`);
      const text = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(text, "image/svg+xml");
      const icons = Array.from(xmlDoc.querySelectorAll('symbol')).map(symbol => symbol.id);
      return icons;
    } catch (error) {
      console.error('Error fetching icons:', error);
      return [];
    }
  };

  useEffect(() => {
    const loadIcons = async () => {
      const icons = await fetchIconList();
      const options:any = icons.map((iconId:any) => ({
        label: <SVG iconId={iconId} className="icon-preview" size={24} />,
        value: iconId
      }));
            
      setIconOptions(options);
    };

    loadIcons();
  }, []);
  useEffect(() => {
    const fetchMenuLabels = async () => {
      if (menuType === 'Submenu') {
        try {
          const response = await fetch(webservice + 'api/AppMenu/MenuLabels', {
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          const result = await response.json();
          const decryptedResult = AesDecrypt(result);
          setRes1(decryptedResult);
          const parentMenuOptions = decryptedResult.map((menu: any) => ({
            value: menu.Menu_Label,
            label: menu.Menu_Label,
          }));
          setParentMenuOptions(parentMenuOptions);
        } catch (error) {
          console.error('Error fetching menu labels:', error);
        }
      }
    };
  
    fetchMenuLabels();
  }, [menuType]);
  
  const updatedata = async (data: any) => {    
    const EncParams = AesEncrypt(JSON.stringify(data));
    
    const res = await fetch(webservice + 'api/AppMenu/updatedata', {
      method: 'POST',
      body: JSON.stringify({EncParams}),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(response => response.json());
    return AesDecrypt(res);
  };
  
  // Modify the AddauditLogdata function
  const updateAuditlog = async (data: any) => {
    data.Userid = localStorage.id
    data.ParentUserId = localStorage.ParentUserId
    const EncParams = AesEncrypt(JSON.stringify(data));
    
    const response = await fetch(webservice + 'api/AppMenu/updateAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };

const getMenuData = async (id: any) => {

    const EncParams = AesEncrypt(id);
    const response = await fetch(webservice + 'api/AppMenu/GetMenuData', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((response) => response.json())
    const TempData = AesDecrypt(response)
    return TempData
}

useEffect(() => {
    const fetchData = async () => {
      const data = localStorage.getItem('MenuEdit');     
      if (data != null) {
        const res = await getMenuData(data);
        updateDetails(res)
        if (res.length > 0) {
          setId(res[0].id);
          setrefId(res[0].RefId);
        }
      }
    };
  
    fetchData();
  }, []);
    
      const updateDetails = (data: any) => {
   
        setinitialAudit({
          MenuLabel: data[0].Menu_Label,
          MenuType: data[0].Menu_type,
          MenuIcon: data[0].Menu_icon,
          MenuOrder: data[0].Menu_order,
          MenuShow: data[0].Menu_show,
          MenuUrl: data[0].Menu_url,
          Menukey: data[0].Menu_key,
          ParentMenu: data[0].Parent_Menu,
          menuAction: data[0].Menu_Action,
          addUrl: data[0].Add_Url,
          editUrl: data[0].Edit_Url,
      })
      setMenuType(data[0].Menu_type)
          const menuActions = {
          add: data[0].Menu_Action.includes('add'),
          edit: data[0].Menu_Action.includes('edit'),
          delete: data[0].Menu_Action.includes('delete'),
          view: data[0].Menu_Action.includes('view'),
        };
        setMenuAction(menuActions)
        setinitialValues({
          MenuLabel: data[0].Menu_Label,
          MenuType: data[0].Menu_type,
          MenuIcon: data[0].Menu_icon,
          MenuOrder: data[0].Menu_order,
          MenuShow: data[0].Menu_show,
          MenuUrl: data[0].Menu_url,
          Menukey: data[0].Menu_key,
          ParentMenu: data[0].Parent_Menu,
          addUrl: data[0].Add_Url,
          editUrl: data[0].Edit_Url,
      })
   
    
      };

  const handleSubmit =async  (data: FormValues) => {

    if (menuType === 'Submenu') {
      MandatoryFields.push('ParentMenu');
    }

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    data.MenuType = menuType;

    let ParentID: number | null = null;
    if (menuType === 'Menu') {
      ParentID = 0;
    } else {
      // Check if data.ParentMenu exists and has a value property
      if (data.ParentMenu) {
        // Access the value property using type assertion
        const par = data.ParentMenu        
        if (par) {
          const foundItem = res1.find(item => item.Menu_Label === par);
          ParentID = foundItem ? foundItem.RefId : null;
        } else {
          ParentID = null;
        }
      } else {
        ParentID = null;
      }
    }
    

    const selectedActions = [];
    if (menuAction.add) {
      selectedActions.push('add');
    }
    if (menuAction.edit) {
      selectedActions.push('edit');
    }
    if (menuAction.delete) {
      selectedActions.push('delete');
    }
    if (menuAction.view) {
      selectedActions.push('view');
    }
    data.menuAction = selectedActions;

    const RequiredData: { [key: string]: string | undefined } = {};
    MandatoryFields.forEach((item) => {
      RequiredData[item] = (data as any)[item];
    });

    let updatedFields: { field: string, before: any, after: any }[] = [];

    for (const key in data as FormValues) {
        const label = fieldMapping[key] || key;
        if (initialAudit && initialAudit[key as keyof FormValues] !== data[key as keyof FormValues]) {

          const beforeValue = initialAudit[key];
          const afterValue = data[key as keyof FormValues];

  
          if (beforeValue !== null && afterValue !== null && !(key === 'menuAction' && beforeValue.join() === afterValue.join())) {
            let field, before, after;
            if (key === 'menuAction') {
              field = label;
              before = beforeValue;
              after = data.menuAction;
            } else {
              field = label;
              before = beforeValue;
              after = afterValue;
            }
            updatedFields.push({
              field: field,
              before: before,
              after: after,
            });
          }
        }
      }
    if (Object.values(RequiredData).every(field => field && field.length > 0)) {

        if (ParentID !== null) {
            (data as any).ParentID = ParentID;
          }
          
      try {
        const ref = await updatedata({ params: data, Id  });
        await updateAuditlog({ updatedFields, refId, Username });
        Swal.fire({
          title: 'Insurance Verification ',
          icon: 'success',
          text: 'Menu Updated Successfully!',
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        setIsSubmitting(false);
        navigate(`/appmenu`);
      } catch (error) {
        console.error('Error adding menu:', error);
        setIsSubmitting(false);
      }
    } else {
      for (const key in RequiredData) {
        if (RequiredData[key] && RequiredData[key]!.length === 0) {
         
        }
      }
    }
  };



  const FORM_VALIDATION = Yup.object().shape({
    MenuLabel: Yup.string().required("Menu Label is required"),
    Menukey: Yup.string().required("Menu Key is required"),
    MenuIcon: Yup.string().required("Menu Icon is required"),
    MenuShow: Yup.string().required("Menu Show is required"),
    ParentMenu: Yup.string().when('MenuType', {
      is: 'Submenu',
      then: (schema) => schema.required("Parent Menu is required"),
      otherwise: (schema) => schema.notRequired()
    }),
  });
  
    
  

  return (
    <>
      <Breadcrumbs mainTitle='Edit Menu' parent='App Menu' title='Edit Menu' to={`/appmenu`} />
      <Fragment>
        <Card>
          <CardHeader style={{ backgroundColor: '#B4B4B3' }}>
            <Col>
              <Button tag={Link} to={`/appmenu`} color='primary' className='align-middle'>
                <ArrowLeftCircle />
              </Button>
            </Col>
            <Col></Col>
          </CardHeader>
          <CardBody className='py-2 my-25'>
          <Formik initialValues={initialValues} validationSchema={FORM_VALIDATION} onSubmit={handleSubmit} enableReinitialize>
              {({ setFieldValue, errors, touched,values }) => {
                  return (
                <div>
                <Form className="form-horizontal">
                  <Row>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Menu Label <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="MenuLabel" type="text" className="form-control" placeholder={"Enter the Menu Label"} />
                        <ErrorMessage name="MenuLabel" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Menu Url </Label>
                        <Field name="MenuUrl" type="text" className="form-control" placeholder={"Enter the Menu Url"} />
                        <ErrorMessage name="MenuUrl" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Menu Key <span style={{ color: 'red' }}> * </span></Label>
                        <Field name="Menukey" type="text" className="form-control" placeholder={"Enter the Menu Key "}
                        onChange={(e: any) => {
                          const { name, value } = e.target;
                          const transformedValue = value.replace(/[^A-Za-z_]/g, '').toUpperCase();
                          setFieldValue(name, transformedValue);}}  />
                        <ErrorMessage name="Menukey" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Menu Order </Label>
                        <Field name="MenuOrder" type="text" className="form-control" placeholder={"Enter the Menu Order "} 
                        onChange={(e: any) => {
                          const { name, value } = e.target;
                          const transformedValue = value.replace(/[^0-9]/g, '')
                          setFieldValue(name, transformedValue);}} />
                        <ErrorMessage name="MenuOrder" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Menu Icon <span style={{ color: 'red' }}> * </span></Label>
                        <Select
                          name="MenuIcon"
                          options={IconOptions}
                          value={IconOptions.find(option => option.value === values.MenuIcon)}
                          className="react-select"
                          classNamePrefix="select"
                          onChange={(selectedOption: any) => {
  
                            setFieldValue('MenuIcon', selectedOption ? selectedOption.value : '');
                          }}
                          
                        />
                        {errors.MenuIcon && touched.MenuIcon && (<ErrorMessage name="MenuIcon" component="span" className="text-danger" />)}
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check>Menu Show <span style={{ color: 'red' }}> * </span></Label>
                        <Select
                          name="MenuShow"
                          options={StatusOptions}
                          value={StatusOptions.find(option => option.value === values.MenuShow)}
                          className="react-select"
                          classNamePrefix="select"
                          onChange={(selectedOption: any) => {
                            setFieldValue('MenuShow', selectedOption ? selectedOption.value : '');
                          }}
                        />
                        {errors.MenuShow && touched.MenuShow && (<ErrorMessage name="MenuShow" component="span" className="text-danger" />)}
                      </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup check inline>
                        <Label check> Menu Action </Label>
                        <div className="form-check-size rtl-input checkbox-checked">
                          <FormGroup check inline>
                            <Label check>
                              <Field
                                className="me-2 form-check-input"
                                type="checkbox"
                                checked={menuAction.add}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setMenuAction({ ...menuAction, add: e.target.checked })
                                }
                              />
                              Add
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Field
                                className="me-2 form-check-input"
                                type="checkbox"
                                checked={menuAction.edit}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setMenuAction({ ...menuAction, edit: e.target.checked })
                                }
                              />
                              Edit
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Field
                                className="me-2 form-check-input"
                                type="checkbox"
                                checked={menuAction.delete}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setMenuAction({ ...menuAction, delete: e.target.checked })
                                }
                              />
                              Delete
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Field
                                className="me-2 form-check-input"
                                type="checkbox"
                                checked={menuAction.view}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setMenuAction({ ...menuAction, view: e.target.checked })
                                }
                              />
                              View
                            </Label>
                          </FormGroup>
                        </div>
                        <ErrorMessage name="menuAction" component="span" className="text-danger" />
                      </FormGroup>
                      {menuAction.add && (
                        <div>
                          <br />
                          <FormGroup>
                            <Label check> Add Menu Url </Label>
                            <Field name="addUrl" type="text" className="form-control" placeholder={"Enter Add URL..."} />
                            {errors.editUrl && touched.editUrl && (<ErrorMessage name="addUrl" component="span" className="text-danger" />)}

                          </FormGroup>
                        </div>
                      )}
                      {menuAction.edit && (
                        <div>
                         {!menuAction.add &&!menuAction.edit&& (
                          <br />)}
                         {!menuAction.add&& (
                          <br />)}
                          <FormGroup>
                            <Label check> Edit Menu Url </Label>
                            <Field name="editUrl" type="text" className="form-control" placeholder={"Enter Edit URL..."} />
                           
                            {errors.editUrl && touched.editUrl && (<ErrorMessage name="editUrl" component="span" className="text-danger" />)}
                          </FormGroup>
                        </div>
                      )}
                    </Col>
                    <Col md={4} xs={12}>
                      <FormGroup>
                        <Label check> Menu Type <span style={{ color: 'red' }}> * </span></Label>
                        <div className="form-check-size rtl-input checkbox-checked">
                          <FormGroup check inline>
                            <Label check>
                              <Field className="me-2 form-check-input" type="radio" name="MenuType" value="Menu" checked={menuType === 'Menu'} onChange={handleMenuTypeChange} />
                              Menu
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Label check>
                              <Field className="me-2 form-check-input" type="radio" name="MenuType" value="Submenu" checked={menuType === 'Submenu'} onChange={handleMenuTypeChange} />
                              Submenu
                            </Label>
                          </FormGroup>
                        </div>
                        <ErrorMessage name="MenuType" component="span" className="text-danger" />
                      </FormGroup>
                    </Col>
                    {menuType === 'Submenu' && (
                      <Col md={4} xs={12}>
                        <FormGroup>
                          <Label check> Parent Menu <span style={{ color: 'red' }}> * </span></Label>
                          <Select
                            name="ParentMenu"
                            options={parentMenuOptions}
                            value={parentMenuOptions.find(option => option.value === values.ParentMenu)}
                            className="react-select"
                            classNamePrefix="select"
                            onChange={(selectedOption: any) => {
                              setFieldValue('ParentMenu', selectedOption ? selectedOption.value : '');
                            }}
                          />
                          {errors.ParentMenu && touched.ParentMenu && (<ErrorMessage name="ParentMenu" component="span" className="text-danger" />)}
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Button type="submit" color="success" className="me-3">Update</Button>
                        <Link to={`/appmenu`}>
                          <Button color="danger">Cancel</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
                </div>
              )}}
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    </>

  );
};

export default EditMenu;