import { Badge, Card, Col, Container, Form, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, Row } from "reactstrap";
// import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Button, CardBody } from 'reactstrap';
import { useEffect, useState,useCallback, forwardRef, InputHTMLAttributes } from 'react';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from "../../../Service/crypto";
import { ChevronLeft, ChevronRight, Edit, Eye, EyeOff, Trash, Trash2 } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import moment from "moment";
import { useForm } from "react-hook-form";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from 'yup';
import MD5 from "crypto-js/md5";
import ReactPaginate from "react-paginate";
import { getUserData,Get_permission } from "./store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../ReaduxToolkit/Hooks";
import websocket_webservice from '../../../Service/websocket_webservice'


const CustomHeader = ({ menuActions, handleFilter, value, handlePerPage, rowsPerPage }: any) => {
  return (
    <div className='client-master-list-table-header w-100 py-2'>
      <Row>
        <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
          <div className='d-flex align-items-center me-4 '>
            <label htmlFor='rows-per-page' className='me-2'>Rows per Page:</label>
            <Input
              type='select'
              id='rows-per-page'
              value={rowsPerPage}
              onChange={handlePerPage}
              className='form-control ms-50 larger-select '
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
            </Input>
          </div>
          {menuActions.includes('add') && (
            <Button tag={Link} to='/addvendor' color='primary' className='ms-2 zoom-on-hover'>
             + Add Team User
            </Button>
          )}
        </Col>
        <Col
          lg='6'
          className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-3'
        >
          <InputGroup className="input-group-search mb-2 zoom-on-hover">
            <Input
              type="text"
              placeholder="🔍 Search...."
              value={value}
              onChange={(e) => handleFilter(e.target.value)}
              className="input-search"
            />
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};


const Vendor = () => {
  interface DeleteRowData {
    id: string;
    TeamName: string;
    vendorName:string;
    emailId: string;
    UserName: string;
    UserType: string;
    ActiveStatus: string;
    Role: string;
    RefId: string;
  }


  const navigate = useNavigate();

  const [scrollInnerModal, setScrollInnerModal] = useState(false);
  const [filteredAuditData, setFilteredAuditData] = useState([]);
  const [refid, setRefid] = useState<string | null>(null);
  const Username = localStorage.UserName;
  const Role = localStorage.Role;
  const [isOpen, setIsOpen] = useState(false);
  const action = { fromUser: true };
  const [eventId, setEventId] = useState<DeleteRowData[]>([]);
  const [eventId1, setEventId1] = useState(0);
  const selectedRowsPerPage: { [key: number]: DeleteRowData[] } = {};
  // const menuActions = ['add','delete'];

  const dispatch = useAppDispatch()
  const store = useSelector((state: any) => state.VendorManagement)
  const [value, setValue] = useState<string>('');
  const [sort, setSort] = useState<string>('asc');
  const [sortColumn, setSortColumn] = useState<any>('id'); // Example initial sort column
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [menuActions, setMenuActions] = useState<string>('');
  const UserType = localStorage.UserType;
  const Id = UserType === 'MOS' ? localStorage.id : localStorage.VendorId;


 
  useEffect(() => {
    if (localStorage.id !== null) {
      dispatch(Get_permission(localStorage.id)).then((response) => {
        const res1 = response.payload;
        const Action = res1[0].Permission.filter(
          (item:any) => item.MenuKey === "TEAM_MANAGEMENT"
        );
        setMenuActions(Action[0].MenuActions);
      });
    }
  }, [localStorage.id])


  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const defaultValues = {
    confirmPassword: "",
    newPassword: ""
  }

  const {
    handleSubmit,
    formState: { errors },
    reset,

    control, watch
  } = useForm({
    defaultValues,
  });

  const handleReset = () => {
    reset({
      confirmPassword: "",
      newPassword: ""
    })
    toggle()
  }
  interface FormValues {
    newPassword: string;
    confirmPassword: string;
  }

  const initialValues: FormValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required('New Password field is required.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
      .required('This field is required.'),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSave = (async (data: FormValues) => {
    const newPassword = data.newPassword
    const hashedPassword = MD5(newPassword).toString();
    const resetpassword: any = { hashedPassword, refid };
    const EncParams = AesEncrypt(JSON.stringify(resetpassword));
    await fetch(webservice + 'api/Authentication/ResetPassword', {
      method: 'POST',
      body: JSON.stringify(EncParams),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        toggle();
        if (response.ok) {
          Swal.fire({
            title: 'Insurance Verification',
            text: 'Password successfully changed',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/vendormanagement`);
            }
          });
        } else {
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  });


  // useEffect(() => {
  //   dispatch(
  //     getUserData({
  //       Id,
  //       UserType,
  //       q: value,
  //       perPage: rowsPerPage,
  //       page: currentPage,
  //       sort,
  //       sortColumn
  //     })
  //   );
  // }, [dispatch, store.data.length]);
  useEffect(() => {
    // Dispatch the initial user data fetch
    dispatch(
      getUserData({
        Id,
        UserType,
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn,
      })
    );

    // WebSocket setup
    const socket = new WebSocket(`${websocket_webservice}ws/usermanagement/`);

    // socket.onopen = () => console.log('WebSocket connected');
    // socket.onclose = () => console.log('WebSocket closed');
    // socket.onerror = (error) => console.error('WebSocket error:', error);

    socket.onmessage = () => {
      // const change = JSON.parse(event.data);
      // console.log('WebSocket message received:', change);

      // Re-dispatch the getUserData action when a WebSocket message is received
      dispatch(
        getUserData({
          Id,
          UserType,
          q: value,
          perPage: rowsPerPage,
          page: currentPage,
          sort,
          sortColumn,
        })
      );
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      if (socket.readyState === 1) { // <-- This is important
        socket.close();
      }
    }
  }, [dispatch, store.data.length, Id, UserType, rowsPerPage, currentPage, sort, sortColumn, value]);

  const handleReloadTable = () => {
    dispatch(
      getUserData({
        Id,
        UserType,
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn
      })
    );
  };


  const handleFilter = (val: any) => {
    setValue(val)
    setCurrentPage(1)
    dispatch(
      getUserData({
        Id,
        UserType,
        q: val,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn,
      })
    )
  }

  const handlePerPage = (e: any) => {
    setCurrentPage(1)
    dispatch(
      getUserData({
        Id,
        UserType,
        q: value,
        perPage: parseInt(e.target.value),
        page: currentPage,
        sort,
        sortColumn,
      })
    )
    setRowsPerPage(parseInt(e.target.value))
  }



  const handlePagination = (page: any) => {
    dispatch(
      getUserData({
        Id,
        UserType,
        q: value,
        perPage: rowsPerPage,
        page: page.selected + 1,
        sort,
        sortColumn,
      })
    )
    setCurrentPage(page.selected + 1)
  }


  const dataToRender = () => {
    const filters: any = {
      q: value

    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (store.data.length > 0) {
      return store.data
    } else if (store.data.length === 0 && isFiltered) {
      return []
    } else {
      return store.allData.slice(0, rowsPerPage)
    }
  }

  const handleSort = (column: any, sortDirection: any) => {
    setSort(sortDirection)
    setSortColumn(column.sortField)
    dispatch(
      getUserData({
        Id,
        UserType,
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort: sortDirection,
        sortColumn: column.sortField
      })
    )
  }

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage))

    return (
      <ReactPaginate
        previousLabel={<ChevronLeft size={16} />}
        nextLabel={<ChevronRight size={16} />}
        breakLabel="..."
        pageCount={count || 1}
        onPageChange={handlePagination}
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        containerClassName="pagination-container"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
      />
    )
  }


  const deleteVendor = async (ids: any) => {

    
    const EncParams = AesEncrypt(JSON.stringify(ids));
    await fetch(webservice + 'api/VendorManagement/DeleteVendorUser', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
  };

  const ViewAuditLog = async (row: any) => {
    const Username = localStorage.UserName;
    const Userid = localStorage.id;
    const RefId = row.RefId;
    const Name = row.UserName;
    const Data = { Username, RefId, Name, Userid };
    const EncParams = AesEncrypt(JSON.stringify(Data));
    const response = await fetch(webservice + 'api/VendorManagement/ViewAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    return response;
  };

  const DeleteAuditLogdata = async (Data: any) => {
    Data.Userid = localStorage.id;
    Data.ParentUserId = localStorage.ParentUserId;
    const EncParams = AesEncrypt(JSON.stringify(Data));
    const response = await fetch(webservice + 'api/VendorManagement/deleteAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    return response;
  };


  const DeleteRow = async (rows: DeleteRowData[] | DeleteRowData) => {
    const deleteRows = async () => {
      const rowCount = Array.isArray(rows) ? rows.length : 1;
      Swal.fire({
        title: "Insurance Verification Platform",
        text: `Are you sure you want to delete ${rowCount} ${rowCount > 1 ? 'Record' : 'Record'}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ms-1",
        },
        buttonsStyling: false,
        allowOutsideClick: false
      }).then(async function (result) {
        if (result.isConfirmed) {
          if (Array.isArray(rows)) {
            const ids = rows.map(row => row.id);
            const Data = rows.map(row => ({
              Username,
              RefId: row.RefId,
              Name: row.UserName
            }));
            await deleteVendor(ids);
            await DeleteAuditLogdata(Data);
          } else {
            const { id, UserName: Name, RefId } = rows;
            const Data = { Username, RefId, Name };
            await deleteVendor([id]);
            await DeleteAuditLogdata([Data]);
          }
          Swal.fire({
            icon: "success",
            title: "Insurance Verification Platform",
            text: "Vendor have been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
            allowOutsideClick: false
          });
          handleReloadTable();
          setEventId1(0)
          setEventId([])
        }
      });
    };
    deleteRows();
  };


  const getAuditlogdata = async (data: any) => {
    const EncParams = AesEncrypt(data);
    const response = await fetch(webservice + 'api/VendorManagement/getAuditLogdata', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    const TempData = AesDecrypt(response);
    return TempData;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (refid !== null) {
        const res = await getAuditlogdata(refid);
        setFilteredAuditData(res);
      }
    };
    fetchData();
  }, [refid]);


  interface BootstrapCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
  }
// Handle individual row checkbox change
const BootstrapCheckbox = forwardRef<HTMLInputElement, BootstrapCheckboxProps>(
  ({ id, ...props }, ref) => (
    <div style={{  position: 'relative', left: '10px' }}>
      <input type='checkbox' id={id} ref={ref} {...props} />
    </div>
  )
);


// Handle individual row checkbox change

const handleChangecheck = useCallback(
  ({ selectedRows }:any) => {


    const selectedEventIds = [];
    selectedRows.forEach((row:any) => {
      selectedEventIds.push(row.id);
    });
    setEventId(selectedRows)
    setEventId1(selectedEventIds.length)
    if (!action.fromUser)
      return;
    selectedRowsPerPage[currentPage] = selectedRows;
  },
  [currentPage, selectedRowsPerPage, action.fromUser]);

  const Columns = [
    
    {
      name: "Vendor",
      selector: (row: DeleteRowData) => row.vendorName,
      sortable: true,
    },
    {
      name: "Team Name",
      selector: (row: DeleteRowData) => row.TeamName,
      sortable: true,
    },
    {
      name: "EmailId",
      selector: (row: DeleteRowData) => row.emailId,
      sortable: true,
    },
    {
      name: "User Name",
      selector: (row: DeleteRowData) => row.UserName,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row: DeleteRowData) => row.UserType
      ,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row: DeleteRowData) => row.Role
      ,
      sortable: true,
    },
    {
      name: "Active Status",
      selector: (row: DeleteRowData) => row.ActiveStatus
      ,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: DeleteRowData) => (
        <>
          <Eye size={17}
            id='positionLeft'
            color='#09d5ff'
            onClick={() => {
              setScrollInnerModal(true)
              setRefid(row.RefId)
            }}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
          {eventId1 <= 1 && (
            menuActions.includes("edit") && (
              <Edit
                size={17}
                id='editIcon'
                color='#FFA500'
                onClick={() => {
                  navigate(`/editvendor`);
                  localStorage.setItem("VendorEdit", row.RefId);
                  ViewAuditLog(row);
                }}
                style={{ cursor: 'pointer', marginRight: '10px' }}
              />
            )
          )}
          {eventId1 === 0 && (
            menuActions.includes("delete") && (
              <Trash2 size={17} id='deleteIcon' color='#FF0000' onClick={() => DeleteRow(row)} style={{ cursor: 'pointer' }} />
            )
          )}
        </>

      ),
    },
  ];



  if (Role === 'Admin' || 'Super Admin') {
    const passwordColumn: any = {
      sortable: true,
      name: 'Password',
      selector: (row: DeleteRowData) => (
        <div className="badge-icon">
          <Badge
            style={{ cursor: 'pointer' }}
            className="badge-button "
            onClick={() => {
              setRefid(row.RefId);
              setIsOpen(true);
            }}
          >
            Reset
          </Badge>
        </div>
      )
    };
    Columns.splice(7, 0, passwordColumn);
  }

  const customStyles = {
    rows: {
      style: {
        '&:hover': {
          backgroundColor: '#f0f0f0',

        },
      },
    },
    headCells: {
      style: {
        backgroundColor: '#355876',  // Change background color of headers
        color: 'white',  // Change text color of headers
        fontSize: '16px',  // Adjust font size of headers
      },
    },
  };

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(currentPage * rowsPerPage, store.total);
  const CustomLoadingComponent = () => {
    return (
      <div className="">
        {store.no_Data === 'There is no Record Found' ? (
          <h4>
            <br/>
            <p>There are no records to display</p>
          </h4>
        ) : (
          <div className="custom-loader">
            <label className="custom-label">Please wait...</label>
            <div className="custom-loading"></div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {/* <Breadcrumbs mainTitle='Vendor Management' title='Vendor Management'/> */}
      <Container fluid>
        <div>
          <Modal isOpen={isOpen} toggle={toggle} className='modal-dialog-centered ' backdrop="static" >
            <ModalHeader style={{ fontWeight: 'bold' }}>Change Password</ModalHeader>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values: any) => handleSave(values)}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <ModalBody>
                    <Col>
                      <Label for="newPassword">New Password<span style={{ color: 'red' }}>*</span></Label>
                      <InputGroup>
                        <Field name="newPassword" placeholder="Enter your New Password" type={showPassword ? 'text' : 'password'} className="form-control" />
                        <InputGroupText className="cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <EyeOff size={14} /> : <Eye size={14} />}
                        </InputGroupText>
                      </InputGroup>
                      <ErrorMessage name="newPassword" component="span" className="text-danger" />
                    </Col>
                    <br />
                    <Col>
                      <Label for="confirmPassword">Confirm Password<span style={{ color: 'red' }}>*</span></Label>
                      <InputGroup>
                        <Field name="confirmPassword" placeholder=" Re-Enter your Password" type={showConfirmPassword ? 'text' : 'password'} className="form-control" />
                        <InputGroupText className="cursor-pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                          {showConfirmPassword ? <EyeOff size={14} /> : <Eye size={14} />}
                        </InputGroupText>
                      </InputGroup>
                      <ErrorMessage name="confirmPassword" component="span" className="text-danger" />
                    </Col>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                    <Button color="danger" type="reset" onClick={handleReset}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
        <div>
          <Modal style={{ maxWidth: '1500px', maxHeight: '600px' }} isOpen={scrollInnerModal} toggle={() => setScrollInnerModal(!scrollInnerModal)} className='modal-dialog-centered modal-lg'>
            <ModalHeader toggle={() => setScrollInnerModal(!scrollInnerModal)}>Client Management Audit Log</ModalHeader>
            <ModalBody>
              <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <thead>
                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                      <th className="audit-tableHeaderStyle">Date & Time</th>
                      <th className="audit-tableHeaderStyle">Action</th>
                      <th className="audit-tableHeaderStyle">Data Set</th>
                      <th className="audit-tableHeaderStyle">Changed By</th>
                    </tr>
                  </thead>
                  <tbody style={{ alignItems: 'center' }}>
                    {filteredAuditData &&
                      filteredAuditData
                        .sort((a: any, b: any) => new Date(b.Modified).getTime() - new Date(a.Modified).getTime())
                        .map((item: any, index: number) => (
                          <tr key={index}>
                            <td className="audit-tableDataStyle">
                              {(() => {
                                const localDate = moment.utc(item.Modified).local().format('MM/DD/YYYY hh:mm:ss A');
                                return localDate;
                              })()}
                            </td>
                            <td className="audit-tableDataStyle">{item.Action}</td>
                            <td className="audit-tableDataStyle">
                              {item.Action === 'VIEW' ? (
                                <span>{item.JsonData}</span>
                              ) : (
                                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                  <thead>
                                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                                      <th className="audit-tableHeaderStyle">Field</th>
                                      {item.Action === 'EDIT' && (
                                        <>
                                          <th className="audit-tableHeaderStyle">Before</th>
                                          <th className="audit-tableHeaderStyle">After</th>
                                        </>
                                      )}
                                      {item.Action === 'ADD' && (
                                        <th className="audit-tableHeaderStyle">Value</th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item.JsonData
                                      .sort((a: any, b: any) => a.field.localeCompare(b.field))
                                      .map((dataItem: any, dataIndex: number) => (
                                        <tr key={dataIndex}>
                                          <td className="audit-tableDataStyle">{dataItem.field}</td>
                                          {item.Action === 'EDIT' && (
                                            <>
                                              <td className="audit-tableDataStyle">
                                                {dataItem.field === 'Permission' && Array.isArray(dataItem.before) ? (
                                                  <table style={{ width: '100%' }}>
                                                    <thead>
                                                      <tr>
                                                        <th className="audit-tableHeaderStyle">Menu</th>
                                                        <th className="audit-tableHeaderStyle">Actions</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {dataItem.before.map((permission: any) => (
                                                        <tr key={permission.MenuLabel}>
                                                          <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                                          <td className="audit-tableDataStyle">
                                                            {Array.isArray(permission.MenuActions)
                                                              ? permission.MenuActions.join(', ')
                                                              : permission.MenuActions}
                                                          </td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </table>
                                                ) : (
                                                  (dataItem.before && dataItem.before.value) || dataItem.before
                                                )}
                                              </td>
                                              <td className="audit-tableDataStyle">
                                                {dataItem.field === 'Permission' && Array.isArray(dataItem.after) ? (
                                                  <table style={{ width: '100%' }}>
                                                    <thead>
                                                      <tr>
                                                        <th className="audit-tableHeaderStyle">Menu</th>
                                                        <th className="audit-tableHeaderStyle">Actions</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {dataItem.after.map((permission: any) => (
                                                        <tr key={permission.MenuLabel}>
                                                          <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                                          <td className="audit-tableDataStyle">
                                                            {Array.isArray(permission.MenuActions)
                                                              ? permission.MenuActions.join(', ')
                                                              : permission.MenuActions}
                                                          </td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </table>
                                                ) : (
                                                  (dataItem.after && dataItem.after.value) || dataItem.after
                                                )}
                                              </td>
                                            </>
                                          )}
                                          {item.Action === 'ADD' && (
                                            <td className="audit-tableDataStyle">
                                              {dataItem.field === 'Password' || dataItem.field === 'Pin' ? (
                                                '******'
                                              ) : dataItem.field === 'Permission' && Array.isArray(dataItem.value) ? (
                                                <table style={{ width: '100%' }}>
                                                  <thead>
                                                    <tr>
                                                      <th className="audit-tableHeaderStyle">Menu</th>
                                                      <th className="audit-tableHeaderStyle">Actions</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {dataItem.value.map((permission: any) => (
                                                      <tr key={permission.MenuLabel}>
                                                        <td className="audit-tableDataStyle">{permission.MenuLabel}</td>
                                                        <td className="audit-tableDataStyle">
                                                          {Array.isArray(permission.MenuActions)
                                                            ? permission.MenuActions.join(', ')
                                                            : permission.MenuActions}
                                                        </td>
                                                      </tr>
                                                    ))}
                                                  </tbody>
                                                </table>
                                              ) : (
                                                (dataItem.value && dataItem.value.value) || dataItem.value
                                              )}
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              )}
                            </td>
                            <td className="audit-tableDataStyle">{item.UserName}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <Row>
          <Col sm={12}>
            <Card >
              <CardBody>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <DataTable
                    persistTableHead
                      subHeader={true}
                      responsive={true}
                      onSort={handleSort}
                      data={dataToRender()}
                      columns={Columns}
                      paginationServer
                      customStyles={customStyles}
                      paginationPerPage={rowsPerPage}
                      paginationDefaultPage={currentPage}
                      selectableRows
                      selectableRowsHighlight
                      selectableRowsComponent={BootstrapCheckbox as unknown as React.ReactNode}
                      onSelectedRowsChange={handleChangecheck}
                      paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                      noDataComponent={<CustomLoadingComponent />}
                      paginationComponent={CustomPagination}
                      subHeaderComponent={
                        <CustomHeader
                          value={value}
                          menuActions={menuActions}
                          rowsPerPage={rowsPerPage}
                          handleFilter={handleFilter}
                          handlePerPage={handlePerPage}

                        />
                      }
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ paddingLeft: '2%' }}>
                        {store.total && (
                          <span><strong>{`${startIndex} to ${endIndex} of ${store.total} entries`}</strong></span>
                        )}
                      </div>
                      <div>
                        <CustomPagination />
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div>
              {eventId1 > 0 && menuActions.includes('delete') && (
                <div className="customizer-links open">
                  <Nav className=" flex-column nav-pills">
                    <a className="nav-link" onClick={() => DeleteRow(eventId)}>
                      <span>Delete</span>
                      <Trash2 className="trash-icon" size={20} />
                    </a>
                  </Nav>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Vendor;
