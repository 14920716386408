import { LI, UL } from "../../AbstractElements";
import SimpleBar from "simplebar-react";
import SidebarBack from "./SidebarBack";
import MenuList from "./MenuList";
import { Link, useNavigate } from "react-router-dom";
import SVG from "../../utils/CommonSvgIcon/SVG";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SidebarMenuTypes } from "../../Data/Layout/Sidebar/SidebarTypes";
import { SidebarMenu } from "../../Data/Layout/Sidebar/Menu";
import { useAppSelector } from "../../ReaduxToolkit/Hooks";
import { AesDecrypt, AesEncrypt } from "../../Service/crypto";
import webservice from "../../Service/webservice";
import websocket_webservice from '../../Service/websocket_webservice'
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

export interface PropsTypes {
  setOpen: (item: boolean) => void
  open?: boolean
  onCloseSidebar?: any
}

interface NavigatorConnection extends Navigator {
  connection?: {
    effectiveType?: string;
    downlink?: number;
  };
}

interface MenuItem {
  RefId: string;
  ParentID: any;
  Menu_type: string;
  Menu_show: string;
  Menu_order: any;
  Menu_Label: string;
  Menu_icon: string;
  Menu_url: string;
}

interface PermissionItem {
  RefId: string;
  MenuActions: any;
  MenuKey: string;
  MenuLabel: string;
}

const SidebarMain = ({ setOpen, open, onCloseSidebar }: PropsTypes) => {
  const { sidebarIconType } = useAppSelector((state) => state.themeCustomizer);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<string[]>([]);
  const [sideMenu, setSideMenu] = useState("")
  const { layout } = useAppSelector((state) => state.themeCustomizer);

  const [navBarItem, setNavBarItem] = useState<SidebarMenuTypes[]>([]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const data = localStorage.id;
  //       const response1 = await fetch(webservice + "api/Get_permission", {
  //         method: "POST",
  //         body: JSON.stringify({ data }),
  //         headers: {
  //           "Content-type": "application/json; charset=UTF-8",
  //         },
  //       });

  //       const jsonData = await response1.json();
  //       const Permission: PermissionItem[] = jsonData[0].Permission;

  //       const response = await fetch(webservice + "api/AppMenu/getmenu", {
  //         method: "GET",
  //         headers: {
  //           "Content-type": "application/json; charset=UTF-8",
  //         },
  //       });

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch menu data");
  //       }

  //       const datas = await response.json();
  //       const menuData: MenuItem[] = AesDecrypt(datas);

  //       const navbarItems = generateNavbarItems(menuData, Permission);
  //       setNavBarItem(navbarItems);
  //     } catch (error) {
  //       console.error("Error fetching menu data:", error);
  //     }
  //   }

  //   if (navBarItem.length === 0) {
  //     fetchData();
  //   }
  // }, [navBarItem]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = localStorage.id;

        // Fetch permissions
        const response1 = await fetch(webservice + "api/Get_permission", {
          method: "POST",
          body: JSON.stringify({ data }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        const jsonData = await response1.json();
        const Permission: PermissionItem[] = jsonData[0].Permission;

        // Fetch menu data
        const response = await fetch(webservice + "api/AppMenu/getmenu", {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch menu data");
        }

        const datas = await response.json();
        const menuData: MenuItem[] = AesDecrypt(datas);

        // Generate and set navbar items based on permissions
        const navbarItems = generateNavbarItems(menuData, Permission);
        setNavBarItem(navbarItems);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    }

    // Fetch data if navbar items are not yet set
    if (navBarItem.length === 0) {
      fetchData();
    }

    // WebSocket setup
    const socket = new WebSocket(`${websocket_webservice}ws/Permission/`);

    // socket.onopen = () => console.log('WebSocket connected');
    // socket.onclose = () => console.log('WebSocket closed');
    // socket.onerror = (error) => console.error('WebSocket error:', error);

    socket.onmessage = () => {
      // const change = JSON.parse(event.data);
      // console.log('WebSocket message received:', change);

      // Re-fetch the data when a WebSocket message is received
      fetchData();
    };

    // Clean up the WebSocket connection when the component unmounts or dependencies change
    return () => {
      if (socket.readyState === 1) { // <-- This is important
        socket.close();
      }
    }
  }, [navBarItem]);


  const generateNavbarItems = (menuData: MenuItem[], Permission: PermissionItem[]) => {
    if (!menuData || !Permission) {
      return [];
    }

    const submenuItems = menuData.filter((menu) => menu.Menu_type === "Submenu");

    const navbarItems = menuData
      .filter(
        (menu) =>
          menu.ParentID === 0 &&
          menu.Menu_show === "yes" &&
          Permission.some((item) => item.RefId === menu.RefId)
      )
      .sort((a, b) => parseInt(a.Menu_order, 10) - parseInt(b.Menu_order, 10))
      .map((menu) => {
        const children = submenuItems
          .filter(
            (child) =>
              child.ParentID === menu.RefId &&
              Permission.some((item) => item.RefId === child.RefId)
          )
          .sort((a, b) => parseInt(a.Menu_order, 10) - parseInt(b.Menu_order, 10))
          .map((child) => ({
            title: child.Menu_Label,
            svgIcon: child.Menu_icon,
            link: child.Menu_url,
            type: "link",
          }));

        return {
          title: menu.Menu_Label,
          svgIcon: menu.Menu_icon,
          link: menu.Menu_url,
          type: "link",
          ...(children.length > 0 && { subMenu: children }),
        };
      });

    return navbarItems;
  };

  const handleClick = (item: SidebarMenuTypes) => {
    if (layout === "material-icon") {
      let menu: string = sideMenu
      menu = sideMenu !== item.title ? item.title : ""
      setSideMenu(menu);
      setOpen(item.subMenu && item.subMenu.length > 0 && menu !== "" ? true : false)
    }
  }



// for logout functions

  const navigate = useNavigate();
  const [networkSpeed, setNetworkSpeed] = useState<string | null>(null);
  const [ipData, setIpData] = useState("");
  const [browserName, setBrowserName] = useState("");
  const [osName, setOsName] = useState("");
  const dispatch = useDispatch();

  // Getting ip address start
  useEffect(() => {
    const getIp = () => {
      fetch('https://api.ipregistry.co/?key=cimfhddjjvc4qu5h')
        .then((response) => response.json())
        .then((data) => {
          setIpData(data.ip);
          setBrowserName(data.user_agent.name);
          setOsName(data.user_agent.os.name);
          const Time = data.time_zone.id;
          localStorage.setItem("timezone", Time);
        })
        .catch((error) => {
          console.error("Error fetching IP address:", error);
        });
    };
    getIp();
  }, []);

  // Setting user network speed start
  useEffect(() => {
    const calculateNetworkSpeed = () => {
      const navigatorConnection = navigator as NavigatorConnection;
      if (navigatorConnection.connection) {
        const connection = navigatorConnection.connection;
        if (connection.effectiveType) {
        }
        if (connection.downlink) {
          const downlinkSpeed = (connection.downlink * 8).toFixed(2);
          setNetworkSpeed(downlinkSpeed + " Mbps");
        }
      }
    };
    calculateNetworkSpeed();
  }, []);


  const userId = localStorage.id;
  const usertype = localStorage.UserType;
  const userName = localStorage.UserName;

  const userlogouthistory = async (UserLogoutinHistory: any) => {
    const EncParams = AesEncrypt(JSON.stringify({ UserLogoutinHistory }));
    const response = await fetch(webservice + 'api/UserlogHistory/Userlogouthistory_add', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };
  const Useractivelist = async (Useractivelist: any) => {

    const EncParams = AesEncrypt(JSON.stringify({ Useractivelist }));
    const response = await fetch(webservice + 'api/ActiveUserList/Useractivelist_update', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    return await response.json();
  };


  const Logout = async() => {
    const username = userName;
    const userid = userId;
    const Usertype = usertype;
    const UserIPAddress = ipData;
    const userBrowser = browserName;
    const userOsName = osName;
    const usernetspeed = networkSpeed;

    try {
      const result = await userlogouthistory({
        username,
        Usertype,
        userid,
        UserIPAddress,
        userBrowser,
        userOsName,
        usernetspeed,
      });
      const data = await Useractivelist({
        username,
        Usertype,
        userid,
        UserIPAddress,
        userBrowser,
        userOsName,
        usernetspeed,
      });

      // Combine result and data into a single object
      const combinedPayload = {
        logHistory: result,
        activeList: data,
      };

      dispatch({ type: 'user/loghistory/fulfilled', payload: combinedPayload });
      localStorage.setItem('login', JSON.stringify(true));
      localStorage.removeItem('Pin');
      localStorage.clear();
      navigate(`/login`);

    }
  catch (error) {
    console.error("Error logging user history:", error);
  } 
  }


  // const caching = () => {
  //     const staticVersion = "1.0.0";
  //     let storedVersion = localStorage.getItem('version');
  //     const navigationEntry = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
  //     const navigationType = navigationEntry.type;
  //     const isPageReload = navigationType === 'reload';
  //     if (!storedVersion) {
  //       localStorage.setItem('version', staticVersion);
  //       return;
  //     }
  //   if (storedVersion !== staticVersion && !isPageReload) {
  //       Logout()
  //     } else if (!isPageReload) {
  //       window.addEventListener('beforeunload', () => {
  //         let versionParts = (storedVersion ?? staticVersion).split('.');
  //         versionParts[versionParts.length - 1] = (parseInt(versionParts[versionParts.length - 1]) + 1).toString();
  //         const newVersion = versionParts.join('.');
  //         localStorage.setItem('version', newVersion);
  //       });
  //     }
  //   };
  //   caching();


// Session timeout function

    // const handleSessionTimeout = () => {
    //   setTimeout(() => {
    //     Logout();
    //   }, 2000);
    // };
  
    // const useSessionTimeout = (onTimeout:any) => {
    //   const timeoutDuration = 30 * 60 * 1000; // 45 minutes in milliseconds
    //   const alertDuration = 30 * 60 * 1000; // 45 minutes in milliseconds
    
    //   useEffect(() => {
    //     if (!localStorage.UserType) return;
    
    //     let timeout:any;
    //     let alertTimeout:any;
    //     let swalTimer:any;
    
    //     const formatTime = (seconds:any) => {
    //       const minutes = Math.floor(seconds / 60);
    //       const remainingSeconds = seconds % 60;
    //       return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    //     };
    
    //     const showAlert = () => {
    //       let remainingTime = alertDuration / 1000; // Convert milliseconds to seconds for the alert countdown
    
    //       Swal.fire({
    //         title: 'Session Expired',
    //         text: `Session will expire in ${formatTime(remainingTime)} due to inactivity.`,
    //         icon: 'warning',
    //         showCancelButton: true,
    //         showConfirmButton: false,
    //         cancelButtonText: 'Cancel',
    //         allowOutsideClick: false,
    //         didOpen: () => {
    //           swalTimer = setInterval(() => {
    //             remainingTime -= 1;
    //             if (remainingTime >= 0) {
    //               Swal.update({
    //                 text: `Session will expire in ${formatTime(remainingTime)} due to inactivity.`,
    //               });
    //             } else {
    //               clearInterval(swalTimer);
    //               Swal.update({
    //                 text: `Session has expired due to inactivity.`,
    //                 showConfirmButton: true,
    //                 showCancelButton: false,
    //               });
    //               onTimeout();
    //             }
    //           }, 1000); // Update every second
    //         }
    //       }).then((result) => {         
    //         if (result.isConfirmed) {
    //           window.location.reload();
    //         } else if(result.isDismissed) {
    //           handleUserActivity()
    //         }else if(!result.isConfirmed&&!result.isDismissed){
    //           onTimeout();
    //         }
    //       });
        
    
    //       alertTimeout = setTimeout(() => {
    //         onTimeout();
    //       }, alertDuration);
    //     };
    
    //     const handleUserActivity = () => {
    //       clearTimeout(timeout);
    //       clearTimeout(alertTimeout);
    //       clearInterval(swalTimer);
    
    //       timeout = setTimeout(() => {
    //         showAlert();
    //       }, timeoutDuration);
    //     };
    
    //     window.addEventListener('mousemove', handleUserActivity);
    //     window.addEventListener('keydown', handleUserActivity);
    //     window.addEventListener('click', handleUserActivity);
    //     window.addEventListener('scroll', handleUserActivity)
    
    //     handleUserActivity(); 
    
    //     return () => {
    //       clearTimeout(timeout);
    //       clearTimeout(alertTimeout);
    //       clearInterval(swalTimer);
    //       window.removeEventListener('mousemove', handleUserActivity);
    //       window.removeEventListener('keydown', handleUserActivity);
    //       window.removeEventListener('click', handleUserActivity);
    //       window.removeEventListener('scroll', handleUserActivity);
    //     };
    //   }, [onTimeout, timeoutDuration, alertDuration]);
    // };
    
    // useSessionTimeout(handleSessionTimeout)


    return (
      <nav className="sidebar-main">
        <div id="sidebar-menu">
          <UL className="sidebar-links simple-list" id="simple-bar">
            <SimpleBar className="w-100 simplebar-scrollable-y h-100">
              <SidebarBack />
              {navBarItem.map((data, index) => (
                <LI className={`sidebar-list`} key={index}>
                  <Link
                    className={`sidebar-link sidebar-title ${sideMenu === data.title ? "active" : ""}`}
                    to={data.link ? data.link : "#"}
                    onClick={() => handleClick(data)}
                  >
                    <SVG
                      className={`${sidebarIconType}-icon`}
                      iconId={`${sidebarIconType === "fill" ? "fill-" : ""}${data.svgIcon
                        }`}
                    />
                    <span>{t(data.title)}</span>

                  </Link>
                  {data.subMenu && data.subMenu.length > 0 && (
                    <UL
                      className="sidebar-submenu custom-scrollbar simple-list"
                      style={{
                        display: `${layout === "material-icon" ? data.title === sideMenu ? "block" : "none" : ""
                          }`,
                      }}
                    >
                      {data.title1 && data.subMenu && data.subMenu.length > 0 && (
                        <li className="sidebar-head">{t(data.title1)}</li>
                      )}
                      {data.subMenu && data.subMenu.length > 0 && (
                        <MenuList
                          subMenuData={data}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          level={0}
                          setOpen={setOpen}
                          onCloseSidebar={onCloseSidebar}
                          sideMenu={sideMenu}
                          setSideMenu={setSideMenu}
                        />
                      )}
                    </UL>
                  )}
                </LI>
              ))}
            </SimpleBar>
          </UL>
        </div>
      </nav>
    );
  };

  export default SidebarMain;
