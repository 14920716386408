import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Table, Button, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Link, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import webservice from '../../../Service/webservice';
import { AesEncrypt, AesDecrypt } from "../../../Service/crypto";
import React, { useEffect, useState, useCallback } from "react";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Edit } from "react-feather";
import moment from "moment";
import classnames from 'classnames';

// Interfaces
interface PatientData {
  appointment: string;
  id: number;
  insure_status: string;
  patient: {
    address: {
      street1: string;
      city: string;
      zip: string;
      state: string;
    };
    coverage: {
      effective_date: string;
      status: string;
    };
    metadata: {
      value: string;
      type: string;
    };
    dob: string;
    first_name: string;
    last_name: string;
    member_id: string;
    name: string;
  };
  payer: {
    claim_address: {
      city: string;
      state: string;
      street1: string;
      street2: string;
      zip: string;
    };
    id: string;
    name: string;
    phone: string;
  };
  plan: {
    metadata: {
      value: string;
      type: string;
      description: string;
      qualifier: string;
    };
    group_number: string;
    group_name: string;
    name: string;
    number: string;
    state: string;
    type: string;
  };
  subscriber: {
    dob: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    member_id: string;
    name: string;
    gender: string;
    address: {
      street1: string;
      city: string;
      zip: string;
      state: string;
    };
    coverage: {
      effective_date: string;
      status: string;
    };
    metadata: {
      value: string;
      type: string;
    };
  };
  coinsurance?: Coinsurance[];
  deductible?: Deductible[];
  maximums?: Maximums[];
  limitations?: Limitations[];
  updated_date: string;
  template_data_get_heading: string;
}

interface Coinsurance {
  network: string;
  category: string;
  insurance_type: string;
  service_type: string;
  amount: string;
  percent: String;
  procedure_code: String;
  limitation: String;
  disclaimers: String;
  quantity: String;
  quantity_qualifier: String;
  limitation_applies_to: String;
  payer_specific_description: String;
  age_limit: {
    age_high_value: String
    age_low_value: String
  };
  metadata: {
    value: string;
    type: string;
  };
}

interface Deductible {
  network: string;
  category: string;
  coverage_level: string;
  plan_period: string;
  amount: number;
  insurance_type: String;
  service_type: String;
  percent: String;
  procedure_code: String;
  limitation: String;
  limitation_applies_to: String;
  disclaimers: String;
  quantity: String;
  quantity_qualifier: String;
  payer_specific_description: String;
  age_limit: {
    age_high_value: String
    age_low_value: String
  };
}

interface PlanData {
  plan: {
    group_number: string;
    group_name: string;
    name: string;
    number: string;
    state: string;
    type: string;
    metadata: {
      value: string;
      type: string;
      description: string;
      qualifier: string;
    }
  };
}

interface Maximums {
  network: string;
  category: string;
  plan_period: string;
  amount: number;
  coverage_level: string;
  insurance_type: String;
  service_type: String;
  percent: String;
  procedure_code: String;
  limitation: String;
  limitation_applies_to: String;
  disclaimers: String;
  quantity: String;
  quantity_qualifier: String;
  payer_specific_description: String;
  age_limit: {
    age_high_value: String
    age_low_value: String
  };
}

interface Limitations {
  network: string;
  category: string;
  insurance_type: String;
  service_type: String;
  percent: String;
  amount: String;
  services_remaining: number;
  limitation: String;
  limitation_applies_to: string[];
  disclaimers: String;
  quantity: String;
  quantity_qualifier: String;
  payer_specific_description: String;
  age_limit: {
    age_high_value: String
    age_low_value: String
  };
  service_dates: {
    procedure_code: String;
    service_date: String;
  }[]
}

// Subcomponents
const Patient = ({ patientData, toggle, isOpen, tempid }: { patientData: PatientData, toggle: () => void, isOpen: boolean, tempid: string }) => {
  useEffect(() => {
  }, [tempid]);

  const renderAddress = () => {
    const address = patientData?.patient?.address;
    const fields: Array<keyof typeof address> = ['street1', 'city', 'state', 'zip'];

    if (!address) {
      return <span className="highlight-empty">No address information available</span>;
    }

    return fields.map((field, index) => {
      const value = address[field] ?? "";
      const className = value ? "" : "highlight-empty";
      return (
        <span key={index} className={className}>
          {value}
          {value && index < fields.length - 1 && ', '}
        </span>
      );
    });
  };

  const isAddressEmpty = () => {
    const address = patientData?.patient?.address;
    if (!address) return true;

    return Object.values(address).every(value => !value);
  };


  return (
    <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
      <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }} onClick={toggle}>
        <Row>
          <Col xs="10">
            <CardTitle tag="h3">
              <strong> Patient Information</strong>
            </CardTitle>
          </Col>
          <Col xs="2" className="ml-auto">
            <Button color="link" onClick={toggle} className="float-right">
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </Col>
        </Row>
      </CardHeader>
      {isOpen && (
        <CardBody>
          <Row>
            <Col xs="6">
              <CardText className={patientData?.patient?.first_name ? "" : "highlight-empty"}>
                &nbsp;  <strong>Name:</strong> {patientData?.patient?.first_name ?? ""} {patientData?.patient?.last_name ?? ""}
              </CardText>
              <CardText className={patientData?.patient?.dob ? "" : "highlight-empty"}>
                &nbsp;  <strong>Date of Birth:</strong> {patientData?.patient?.dob ?? ""}
              </CardText>
              <CardText className={patientData?.patient?.member_id ? "" : "highlight-empty"}>
                &nbsp;  <strong>Member ID:</strong> {patientData?.patient?.member_id ?? ""}
              </CardText>
              <CardText className={isAddressEmpty() ? "highlight-empty" : ""}>
                <strong>Address:</strong> {renderAddress()}
              </CardText>
            </Col>
            <Col xs="6">
              <CardText className={patientData?.patient?.coverage?.effective_date ? "" : "highlight-empty"}>
                &nbsp;  <strong>Coverage:</strong> {patientData?.patient?.coverage?.effective_date ?? ""}
              </CardText>
              <CardText className={patientData?.patient?.coverage?.status ? "" : "highlight-empty"}>
                &nbsp;  <strong>Coverage Status:</strong> {patientData?.patient?.coverage?.status ?? ""}
              </CardText>
              {/* <CardText>
                <strong>Meta Value:</strong> {patientData?.patient?.metadata?.value ?? ""}
              </CardText>
              <CardText>
                <strong>Meta Type:</strong> {patientData?.patient?.metadata?.type ?? ""}
              </CardText> */}
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
};


const Plan = ({ planData, toggle, isOpen, tempid }: { planData: PlanData, toggle: () => void, isOpen: boolean, tempid: string }) => {
  useEffect(() => {
  }, [tempid]);

  return (
    <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
      <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }} onClick={toggle}>
        <Row>
          <Col xs="10">
            <CardTitle tag="h3">
              <strong>Plan Information</strong>
            </CardTitle>
          </Col>
          <Col xs="2" className="ml-auto">
            <Button color="link" onClick={toggle} className="float-right">
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </Col>
        </Row>
      </CardHeader>
      {isOpen && (
        <CardBody>
          <Row>
            <Col xs="4">
              <CardText className={planData?.plan?.name ? "" : "highlight-empty"}>
                <strong>Name:</strong>
                &nbsp;  {planData?.plan?.name ?? ""}
              </CardText>
              <CardText className={planData?.plan?.number ? "" : "highlight-empty"}>
                <strong>Plan Number:</strong>
                &nbsp;  {planData?.plan?.number ?? ""}
              </CardText>
              <CardText className={planData?.plan?.state ? "" : "highlight-empty"}>
                <strong>State:</strong>
                &nbsp;  {planData?.plan?.state ?? ""}
              </CardText>
              <CardText className={planData?.plan?.group_number ? "" : "highlight-empty"}>
                <strong>Group Number:</strong>
                &nbsp;  {planData?.plan?.group_number ?? ""}
              </CardText>
            </Col>
            {/* <Col xs="4">
              {planData?.plan?.metadata.map((meta: any, index: any) => (
                <React.Fragment key={index}>
              <CardText>
                <strong>Meta Type:</strong>
                {planData?.plan?.metadata?.type ?? ""}
              </CardText>
              <CardText>
                    <strong>Meta Value:</strong> {meta.value}
                  </CardText>
                  <CardText>
                    <strong>Meta Description:</strong> {meta.description}
                  </CardText>
                  <CardText>
                    <strong>Meta Qualifier:</strong> {meta.qualifier}
                  </CardText>
              </React.Fragment>
              ))}
            </Col> */}
            {/* <CardText>
                <strong>Meta Value:</strong> {planData?.plan?.metadata?.value ?? ""}
              </CardText>
              <CardText>
                <strong>Meta Description:</strong> {planData?.plan?.metadata?.description ?? ""}
              </CardText>
              <CardText>
                <strong>Meta Qualifier:</strong> {planData?.plan?.metadata?.qualifier ?? ""}
              </CardText> */}
            <Col xs="4">
              <CardText className={planData?.plan?.type ? "" : "highlight-empty"}>
                &nbsp;  <strong>Plan Type:</strong>
                {planData?.plan?.type ?? ""}
              </CardText>
              <CardText className={planData?.plan?.group_name ? "" : "highlight-empty"}>
                &nbsp;   <strong>Group Name:</strong>
                {planData?.plan?.group_name ?? ""}
              </CardText>
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
}

const Payer = ({ payerData, toggle, isOpen, tempid }: { payerData: PatientData, toggle: () => void, isOpen: boolean, tempid: string }) => {
  useEffect(() => {
  }, [tempid]);

  const renderAddress = () => {
    const address = payerData?.payer?.claim_address;
    const fields: Array<keyof typeof address> = ['street1', 'street2', 'city', 'state', 'zip'];

    if (!address) {
      return <span className="highlight-empty" />;
    }

    return fields.map((field, index) => {
      const value = address[field] ?? "";
      const className = value ? "" : "highlight-empty";
      return (
        <span key={index} className={className}>
          {value}
          {value && index < fields.length - 1 && ', '}
        </span>
      );
    });
  };

  const isAddressEmpty = () => {
    const address = payerData?.payer?.claim_address;
    if (!address) return true;

    return Object.values(address).every(value => !value);
  }

  return (
    <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
      <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }} onClick={toggle}>
        <Row>
          <Col xs="10">
            <CardTitle tag="h3">
              <strong>  Payer Information</strong>
            </CardTitle>
          </Col>
          <Col xs="2" className="ml-auto">
            <Button color="link" onClick={toggle} className="float-right">
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </Col>
        </Row>
      </CardHeader>
      {isOpen && (
        <CardBody>
          <Row>
            <Col xs="6">
              <CardText className={payerData?.payer?.id ? "" : "highlight-empty"}>
                <strong>Payer Id:</strong>&nbsp; {payerData?.payer?.id ?? ""}
              </CardText>
              <CardText className={payerData?.payer?.name ? "" : "highlight-empty"}>
                <strong>Name:</strong> &nbsp;{payerData?.payer?.name ?? ""}
              </CardText>
              <CardText className={payerData?.payer?.phone ? "" : "highlight-empty"}>
                <strong>Phone:</strong>&nbsp; {payerData?.payer?.phone ?? ""}
              </CardText>
            </Col>
            <Col xs="6">

              <CardText className={isAddressEmpty() ? "highlight-empty" : ""}>
                <strong>Address:</strong>&nbsp; {renderAddress()}
              </CardText>
              {/* <CardText>
                <strong>Address:</strong>
                &nbsp; <span className={payerData?.payer?.claim_address?.street1 ? "" : "highlight-empty"}>{payerData?.payer?.claim_address?.street1 ?? ""}</span>,
                &nbsp; <span className={payerData?.payer?.claim_address?.street2 ? "" : "highlight-empty"}>{payerData?.payer?.claim_address?.street2 ?? ""}</span>,
                &nbsp; <span className={payerData?.payer?.claim_address?.city ? "" : "highlight-empty"}>{payerData?.payer?.claim_address?.city ?? ""}</span>,
                &nbsp; <span className={payerData?.payer?.claim_address?.state ? "" : "highlight-empty"}>{payerData?.payer?.claim_address?.state ?? ""}</span>,
                &nbsp; <span className={payerData?.payer?.claim_address?.zip ? "" : "highlight-empty"}>{payerData?.payer?.claim_address?.zip ?? ""}</span>
              </CardText> */}
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
}

const Subscriber = ({ subscriberData, toggle, isOpen, tempid }: { subscriberData: PatientData, toggle: () => void, isOpen: boolean, tempid: string }) => {
  useEffect(() => {
  }, [tempid]);

  const isNameEmpty = () => {
    const firstName = subscriberData?.subscriber?.first_name ?? "";
    const lastName = subscriberData?.subscriber?.last_name ?? "";
    return !firstName && !lastName;
  };

  const renderAddress = () => {
    const address = subscriberData?.subscriber?.address;
    const fields: Array<keyof typeof address> = ['street1', 'city', 'state', 'zip'];

    if (!address) {
      return <span className="highlight-empty" />;
    }

    return fields.map((field, index) => {
      const value = address[field] ?? "";
      const className = value ? "" : "highlight-empty";
      return (
        <span key={index} className={className}>
          {value}
          {value && index < fields.length - 1 && ', '}
        </span>
      );
    });
  };

  const isAddressEmpty = () => {
    const address = subscriberData?.subscriber?.address;
    if (!address) return true;

    return Object.values(address).every(value => !value);
  };

  return (
    <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
      <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }} onClick={toggle}>
        <Row>
          <Col xs="10">
            <CardTitle tag="h3">
              <strong> Subscriber Information</strong>
            </CardTitle>
          </Col>
          <Col xs="2" className="ml-auto">
            <Button color="link" onClick={toggle} className="float-right">
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </Col>
        </Row>
      </CardHeader>
      {isOpen && (
        <CardBody>
          <Row>
            <Col xs="4">
              <CardText className={isNameEmpty() ? "highlight-empty" : ""}>
                <strong>Name:</strong>
                &nbsp;  {subscriberData?.subscriber?.first_name ?? ""} {subscriberData?.subscriber?.last_name ?? ""}
              </CardText>
              <CardText className={subscriberData?.subscriber?.dob ? "" : "highlight-empty"}>
                <strong>Date of Birth:</strong>
                &nbsp; {subscriberData?.subscriber?.dob ?? ""}
              </CardText>
              <CardText className={subscriberData?.subscriber?.gender ? "" : "highlight-empty"}>
                <strong>Gender:</strong>
                &nbsp; {subscriberData?.subscriber?.gender ?? ""}
              </CardText>
            </Col>
            <Col xs="4">
              <CardText className={subscriberData?.subscriber?.member_id ? "" : "highlight-empty"}>
                <strong>Member ID:</strong>
                &nbsp; {subscriberData?.subscriber?.member_id ?? ""}
              </CardText>
              <CardText className={isAddressEmpty() ? "highlight-empty" : ""}>
                <strong>Address:</strong>
                &nbsp;{renderAddress()}
              </CardText>
              {/* <CardText> 
                <strong>Name:</strong>
                &nbsp;  {subscriberData?.subscriber?.first_name ?? ""} {subscriberData?.subscriber?.last_name ?? ""}
              </CardText> */}
              <CardText className={subscriberData?.subscriber?.coverage?.effective_date ? "" : "highlight-empty"}>
                <strong>Coverage:</strong>
                &nbsp;{subscriberData?.subscriber?.coverage?.effective_date ?? ""}
              </CardText>
            </Col>
            <Col xs="4">
              <CardText className={subscriberData?.subscriber?.coverage?.status ? "" : "highlight-empty"}>
                <strong>Coverage Status:</strong>
                &nbsp;{subscriberData?.subscriber?.coverage?.status ?? ""}
              </CardText>
              {/* <CardText>
                <strong>Meta Value:</strong>
                {subscriberData?.subscriber?.metadata?.value ?? ""}
              </CardText>
              <CardText>
                <strong>Meta Type:</strong>
                {subscriberData?.subscriber?.metadata?.type ?? ""}
              </CardText> */}
            </Col>
          </Row>
        </CardBody>

      )}
    </Card>
  );
};

const CoinsuranceTable = ({ coinsuranceData, toggle, isOpen, tempid }: { coinsuranceData: Coinsurance[], toggle: () => void, isOpen: boolean, tempid: string }) => {
  useEffect(() => {
  }, [tempid]);

  const tableHeaderStyle = {
    width: 'auto', // or a specific width like '100px'
    textAlign: 'center' as 'center',
    whiteSpace: 'nowrap',
  };

  return (
    <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
      <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }} onClick={toggle}>
        <Row>
          <Col xs="10">
            <CardTitle tag="h3">
              <strong> Coinsurance Information</strong>
            </CardTitle>
          </Col>
          <Col xs="2" className="ml-auto">
            <Button color="link" onClick={toggle} className="float-right">
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </Col></Row>
      </CardHeader>
      {isOpen && (
        <CardBody >
          <div className="table-container">
            <Table bordered >
              <thead>
                <tr>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Network</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Category</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Insurance Type</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Service Type</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Limitation</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Procedure code</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Disclaimers</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Quantity</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Quantity qualifier</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Limitation Applies to</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Payer specific Description</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Age High value</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Age Low value</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Meta value</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Meta Type</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Percent</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {coinsuranceData?.map((coinsurance, index) => (
                  <tr key={index}>
                    <td>{coinsurance.network ?? ""}</td>
                    <td>{coinsurance.category ?? ""}</td>
                    <td>{coinsurance.insurance_type ?? ""}</td>
                    <td>{coinsurance.service_type ?? ""}</td>
                    <td>{coinsurance.procedure_code ?? ""}</td>
                    <td>{coinsurance.limitation ?? ""}</td>
                    <td>{coinsurance.disclaimers ?? ""}</td>
                    <td>{coinsurance.quantity ?? ""}</td>
                    <td>{coinsurance.quantity_qualifier ?? ""}</td>
                    <td>{coinsurance.limitation_applies_to ?? ""}</td>
                    <td>{coinsurance.payer_specific_description ?? ""}</td>
                    <td>{coinsurance.age_limit?.age_high_value ?? ""}</td>
                    <td>{coinsurance.age_limit?.age_low_value ?? ""}</td>
                    <td>{coinsurance.metadata?.value ?? ""}</td>
                    <td>{coinsurance.metadata?.type ?? ""}</td>
                    <td>{coinsurance.percent ?? ""}</td>
                    <td>{coinsurance.amount ?? ""}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      )}
    </Card>
  )
}

const DeductibleTable = ({ deductibleData, toggle, isOpen, tempid }: { deductibleData: Deductible[], toggle: () => void, isOpen: boolean, tempid: string }) => {
  useEffect(() => {
  }, [tempid]);

  const tableHeaderStyle = {
    width: 'auto', // or a specific width like '100px'
    textAlign: 'center' as 'center',
    whiteSpace: 'nowrap',
  };

  return (
    <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
      <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }} onClick={toggle}>
        <Row>
          <Col xs="10">
            <CardTitle tag="h3" className="card-title">
              <strong>Deductible Information</strong>
            </CardTitle>
          </Col>
          <Col xs="2" className="ml-auto">
            <Button color="link" onClick={toggle} className="float-right">
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </Col>
        </Row>
      </CardHeader>
      {isOpen && (
        <CardBody>
          <div className="table-container">
            <Table bordered>
              <thead>
                <tr>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Network</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Category</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Coverage Level</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Plan Period</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Insurance Type</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Service Type</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Procedure Code</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Limitation</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Limitation Applies to</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Disclaimers</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Quantity</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Quantity Qualifier</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Payer Specific Description</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Age Low Type</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Age High Value</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Percent</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {deductibleData?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.network ?? ""}</td>
                    <td>{item.category ?? ""}</td>
                    <td>{item.coverage_level ?? ""}</td>
                    <td>{item.plan_period ?? ""}</td>
                    <td>{item.insurance_type ?? ""}</td>
                    <td>{item.service_type ?? ""}</td>
                    <td>{item.procedure_code ?? ""}</td>
                    <td>{item.limitation ?? ""}</td>
                    <td>{item.limitation_applies_to ?? ""}</td>
                    <td>{item.disclaimers ?? ""}</td>
                    <td>{item.quantity ?? ""}</td>
                    <td>{item.quantity_qualifier ?? ""}</td>
                    <td>{item.payer_specific_description ?? ""}</td>
                    <td>{item.age_limit?.age_low_value ?? ""}</td>
                    <td>{item.age_limit?.age_high_value ?? ""}</td>
                    <td>{item.percent ?? ""}</td>
                    <td>{item.amount ?? ""}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      )}
    </Card>
  );
}

const MaximumsTable = ({ maximumsData, toggle, isOpen, tempid }: { maximumsData: Maximums[], toggle: () => void, isOpen: boolean, tempid: string }) => {
  useEffect(() => {
  }, [tempid]);

  const tableHeaderStyle = {
    width: 'auto', // or a specific width like '100px'
    textAlign: 'center' as 'center',
    whiteSpace: 'nowrap',
  };

  return (
    <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
      <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }} onClick={toggle}>
        <Row>
          <Col xs="10">
            <CardTitle tag="h3" className="card-title">
              <strong>  Maximums Information</strong>
            </CardTitle>
          </Col>
          <Col xs="2" className="ml-auto">
            <Button color="link" onClick={toggle} className="float-right">
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </Col>
        </Row>
      </CardHeader>
      {isOpen && (
        <CardBody>
          <div className="table-container">
            <Table bordered>
              <thead>
                <tr>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Network</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Category</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Coverage Level</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Plan Period</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Insurance Type</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Service Type</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Procedure Code</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Limitation</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Limitation Applies to</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Disclaimers</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Quantity</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Quantity Qualifier</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Payer Specific Description</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Age Low Type</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Age High Value</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Percent</th>
                  <th className="table-DataTables1" style={tableHeaderStyle}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {maximumsData?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.network ?? ""}</td>
                    <td>{item.category ?? ""}</td>
                    <td>{item.coverage_level ?? ""}</td>
                    <td>{item.plan_period ?? ""}</td>
                    <td>{item.insurance_type ?? ""}</td>
                    <td>{item.service_type ?? ""}</td>
                    <td>{item.procedure_code ?? ""}</td>
                    <td>{item.limitation ?? ""}</td>
                    <td>{item.limitation_applies_to ?? ""}</td>
                    <td>{item.disclaimers ?? ""}</td>
                    <td>{item.quantity ?? ""}</td>
                    <td>{item.quantity_qualifier ?? ""}</td>
                    <td>{item.payer_specific_description ?? ""}</td>
                    <td>{item.age_limit?.age_low_value ?? ""}</td>
                    <td>{item.age_limit?.age_high_value ?? ""}</td>
                    <td>{item.percent ?? ""}</td>
                    <td>{item.amount ?? ""}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      )}
    </Card>
  )
}
const Template = ({ template_data_get_heading, toggle, isOpen, tempid }: { template_data_get_heading: string, toggle: () => void, isOpen: boolean, tempid: string }) => {

  useEffect(() => {
  }, [tempid]);

  return (
    <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
      <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }} onClick={toggle}>
        <Row>
          <Col xs="10">
            <CardTitle tag="h3" className="card-title">
              <strong>Template Information</strong>
            </CardTitle>
          </Col>
          <Col xs="2" className="ml-auto">
            <Button color="link" onClick={toggle} className="float-right">
              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </Col>
        </Row>
      </CardHeader>
      {isOpen && (
        <CardBody>
          {Object.entries(template_data_get_heading).map(([key, value]) => (
            <div key={key} style={{ marginBottom: '10px' }}>
              <strong>{key.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())}:</strong>
              <span>{value}</span>
            </div>
          ))}

        </CardBody>
      )}
    </Card>
  );
};

// const LimitationsTable = ({ limitationsData, toggle, isOpen, tempid }: { limitationsData: Limitations[], toggle: () => void, isOpen: boolean, tempid: string }) => {
//   useEffect(() => {
//   }, [tempid]);

//   const tableHeaderStyle = {
//     width: '100px', // or a specific width like '100px'
//     textAlign: 'center' as 'center',
//     whiteSpace: 'nowrap',
//   };

//   return (
//     <Card className="equal-height mouse_over-card" style={{ boxShadow: '0 0 10px #26394b' }}>
//       <CardHeader style={{ backgroundColor: '#2c4358', color: 'white', cursor: 'pointer' }} onClick={toggle}>
//         <Row>
//           <Col xs="10">
//             <CardTitle tag="h3">
//               <strong>  Limitations Information</strong>
//             </CardTitle>
//           </Col>
//           <Col xs="2" className="ml-auto">
//             <Button color="link" onClick={toggle} className="float-right">
//               {isOpen ? <FaChevronUp /> : <FaChevronDown />}
//             </Button>
//           </Col></Row>
//       </CardHeader>
//       {isOpen && (
//         <CardBody>
//           <div className="table-container">
//             <Table bordered >
//               <thead>
//                 <tr>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Network</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Category</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Insurance Type</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Service Type</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Services Remaining</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Limitation</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Limitation Applies to</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Quantity</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Quantity Qualifier</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Payer Specific Description</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Disclaimers</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Age Low Type</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Age High Value</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Disclaimers</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Percent</th>
//                   <th className="table-DataTables1" style={tableHeaderStyle}>Amount</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {limitationsData.map((item, index) => (
//                   <tr key={index}>
//                     <td className="table-DataTablesData">{item.network ?? ""}</td>
//                     <td className="table-DataTablesData">{item.category ?? ""}</td>
//                     <td className="table-DataTablesData">{item.insurance_type ?? ""}</td>
//                     <td className="table-DataTablesData">{item.service_type ?? ""}</td>
//                     <td className="table-DataTablesData">{item.services_remaining ?? ""}</td>
//                     <td className="table-DataTablesData">{item.limitation ?? ""}</td>
//                     <td className="table-DataTablesData">
//                     <ul>
//                         {item.limitation_applies_to && item.limitation_applies_to.length > 0 ? (
//                           item.limitation_applies_to.map((appliesTo, idx) => (
//                             <li key={idx}>{appliesTo}</li>
//                           ))
//                         ) : (
//                           <li></li>
//                         )}
//                       </ul>
//                     </td>
//                     <td className="table-DataTablesData">{item.quantity ?? ""}</td>
//                     <td className="table-DataTablesData">{item.quantity_qualifier ?? ""}</td>
//                     <td className="table-DataTablesData">{item.payer_specific_description ?? ""}</td>
//                     <td className="table-DataTablesData">{item.disclaimers ?? ""}</td>
//                     <td className="table-DataTablesData">{item.age_limit?.age_low_value ?? ""}</td>
//                     <td className="table-DataTablesData">{item.age_limit?.age_high_value ?? ""}</td>
//                     <td className="table-DataTablesData">{item.percent ?? ""}</td>
//                     <td className="table-DataTablesData">{item.amount ?? ""}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//           </div>
//         </CardBody>
//       )}
//     </Card>
//   )
// }

const PatientDetails = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const view: any = queryParams.get('view');
  const [data, setData] = useState<PatientData[]>([]);
  const [expandAll, setExpandAll] = useState(true); // State for button label
  const navigate = useNavigate();

  // Expand/Collapse states
  const [isPatientOpen, setPatientOpen] = useState(true);
  const [isPlanOpen, setPlanOpen] = useState(true);
  const [isPayerOpen, setPayerOpen] = useState(true);
  const [isSubscriberOpen, setSubscriberOpen] = useState(true);
  const [isCoinsuranceOpen, setCoinsuranceOpen] = useState(true);
  const [isDeductibleOpen, setDeductibleOpen] = useState(true);
  const [isMaximumsOpen, setMaximumsOpen] = useState(true);
  const [isLimitationsOpen, setLimitationsOpen] = useState(true);

  // Toggle functions
  const togglePatient = () => setPatientOpen(!isPatientOpen);
  const togglePlan = () => setPlanOpen(!isPlanOpen);
  const togglePayer = () => setPayerOpen(!isPayerOpen);
  const toggleSubscriber = () => setSubscriberOpen(!isSubscriberOpen);
  const toggleCoinsurance = () => setCoinsuranceOpen(!isCoinsuranceOpen);
  const toggleDeductible = () => setDeductibleOpen(!isDeductibleOpen);
  const toggleMaximums = () => setMaximumsOpen(!isMaximumsOpen);
  const toggleLimitations = () => setLimitationsOpen(!isLimitationsOpen);

  const toggleAll = useCallback(() => {
    const allOpen = isPatientOpen && isPlanOpen && isPayerOpen && isSubscriberOpen && isCoinsuranceOpen && isDeductibleOpen && isMaximumsOpen;
    setPatientOpen(!allOpen);
    setPlanOpen(!allOpen);
    setPayerOpen(!allOpen);
    setSubscriberOpen(!allOpen);
    setCoinsuranceOpen(!allOpen);
    setDeductibleOpen(!allOpen);
    setMaximumsOpen(!allOpen);
    setLimitationsOpen(!allOpen);
    setExpandAll(!allOpen);
  }, [isPatientOpen, isPlanOpen, isSubscriberOpen, isPayerOpen, isCoinsuranceOpen, isDeductibleOpen, isMaximumsOpen, expandAll]);

  const handleSubmit = useCallback(() => {
    // Here you can handle form submission logic
    toggleAll();
  }, [toggleAll]);




  const fetchData = useCallback(async () => {
    try {
      const EncParams = AesEncrypt(JSON.stringify({ id: id! })); // Add a non-null assertion here
      const response = await fetch(webservice + 'api/Patient/GetViewPatientData', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      });
      const result = await response.json();
      const decryptedResult = AesDecrypt(result);


      if (decryptedResult === "There is no Record Found") {
        setData([]);
      } else {
        setData(decryptedResult);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
    // startViewTimer();

    return () => {
      stopViewTimer();
    };
  }, [fetchData]);



  const startEditTimer = () => {
    const startTime = new Date().getTime();
    localStorage.setItem('editStartTime', startTime.toString());
  };

  // const startViewTimer = () => {
  //   const startTime = new Date().getTime();

  //   localStorage.setItem('ViewStartTime', startTime.toString());
  // };

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [totalViewTime, setTotalViewTime] = useState("00:00:00");
  const [ViewEstTime, setViewEstTime] = useState("");

  useEffect(() => {
    const storedStartTime = localStorage.getItem('ViewStartTime');
    if (storedStartTime) {
      const startTime = parseInt(storedStartTime, 10);
      startViewTimer(startTime);
    } else {
      const startTime = new Date().getTime();
      localStorage.setItem('ViewStartTime', startTime.toString());
      startViewTimer(startTime);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const startViewTimer = (startTime: number) => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const duration = currentTime - startTime;
      const formattedTime = moment.utc(duration).format('HH:mm:ss');
      setTotalViewTime(formattedTime);
      setViewEstTime(`${new Date().toISOString().slice(0, 10)} ${formattedTime}`); // Date and time
    }, 1000);

    setIntervalId(interval);
  };

  const stopViewTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    localStorage.removeItem('ViewStartTime');
    setTotalViewTime("00:00:00");
    setViewEstTime(""); // Reset ViewEstTime if needed
  };

  const handleback = async () => {
    await timesave();
    if (view === 'PatientsView') {
      navigate(`/patients`);
    } else if (view === 'ManualPatientView') {
      navigate(`/BulkUpload?view=manual`);
    } else {
      navigate(view);
    }
  };

  const totalupdatetime = totalViewTime
  const dateupdatetime = ViewEstTime
  const Username = localStorage.UserName;
  const Id = id;
  const Action = 'VIEW'

  const timesave = async () => {
    stopViewTimer();
    const EncParams = AesEncrypt(JSON.stringify({ data: Id, totalupdatetime, dateupdatetime, Username, Action }));
    const response = await fetch(webservice + 'api/Patient/Viewtime', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
  }
  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <Breadcrumbs mainTitle='Patient Eligibility Info' parent="Patient" title="Patient Eligibility Info" to={`/patients`} />
      <Container fluid>
        <Row>
          <Col xs="2">
            <Button color="primary" tag={Link} to='/patients' onClick={handleback} className="ml-2">
              Back
            </Button>
          </Col>
          <Col xs="4">
            <Nav tabs className="custom-tabs">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' }, 'custom-tab')}
                  onClick={() => { toggleTab('1'); }}
                >
                  API View
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' }, 'custom-tab')}
                  onClick={() => { toggleTab('2'); }}
                >
                  Template View
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="2">
            <div className="d-flex justify-content-start mb-3">
              <Button color="primary" onClick={() => {
                startEditTimer();
                timesave()
                navigate(`/edit-patient/${id}`);
              }} >
                ✏️ Edit Patient Details
              </Button>
            </div>
          </Col>


          <Col xs="2">
            <div className="d-flex justify-content-end mb-3">
              <Button color="primary" onClick={handleSubmit}>
                {expandAll ? 'Collapse All' : 'Expand All'}
              </Button>
            </div>
          </Col>
        </Row>
        <TabContent activeTab={activeTab} className="w-100">
          <TabPane tabId="1">
            <br />
            <Row>
              {data.map((patientData, index) => (
                <Col key={index} sm="4" md="6" lg="12">
                  <Patient patientData={patientData} toggle={togglePatient} isOpen={isPatientOpen} tempid={id ?? ''} />
                </Col>
              ))}
            </Row>
            <br />
            <Row>
              {data.map((planData, index) => (
                <Col key={index} sm="12" md="6" lg="12">
                  <Plan planData={planData} toggle={togglePlan} isOpen={isPlanOpen} tempid={id ?? ''} />
                </Col>
              ))}
            </Row>
            <br />
            <Row>
              {data.map((payerData, index) => (
                <Col key={index} sm="12" md="6" lg="12">
                  <Payer payerData={payerData} toggle={togglePayer} isOpen={isPayerOpen} tempid={id ?? ''} />
                </Col>
              ))}
            </Row>
            <br />
            <Row>
              {data.map((subscriberData, index) => (
                <Col key={index} sm="12" md="6" lg="12">
                  <Subscriber subscriberData={subscriberData} toggle={toggleSubscriber} isOpen={isSubscriberOpen} tempid={id ?? ''} />
                </Col>
              ))}
            </Row>
            <br />
            <Row>
              {data.map((item, index) => (
                item.coinsurance && (
                  <Col key={`coinsurance-${index}`} sm="12" md="6" lg="12">
                    <CoinsuranceTable coinsuranceData={item.coinsurance} toggle={toggleCoinsurance} isOpen={isCoinsuranceOpen} tempid={id ?? ''} />
                  </Col>
                )))}
            </Row>
            <br />
            <Row>
              {data.map((item, index) => (
                item.deductible && (
                  <Col key={`deductible-${index}`} sm="12" md="6" lg="12">
                    <DeductibleTable deductibleData={item.deductible} toggle={toggleDeductible} isOpen={isDeductibleOpen} tempid={id ?? ''} />
                  </Col>
                )))}
            </Row>
            <br />
            <Row>
              {data.map((item, index) => (
                item.maximums && (
                  <Col key={`maximums-${index}`} sm="12" md="6" lg="12">
                    <MaximumsTable maximumsData={item.maximums} toggle={toggleMaximums} isOpen={isMaximumsOpen} tempid={id ?? ''} />
                  </Col>
                )))}
            </Row>
            {/* <br />
        <Row>
          {data.map((item, index) => (
            item.limitations && (
              <Col key={`limitations-${index}`} sm="12" md="6" lg="12">
                <LimitationsTable limitationsData={item.limitations} toggle={toggleLimitations} isOpen={isLimitationsOpen} tempid={id ?? ''} />
              </Col>
            )))}
        </Row> */}
          </TabPane>
          <TabPane tabId="2">
            <br />
            <Row>
              {data.map((item, index) => (
                item.template_data_get_heading && (
                  <Col key={`string-${index}`} sm="12" md="6" lg="12">
                    <Template template_data_get_heading={item.template_data_get_heading} toggle={toggleMaximums} isOpen={isMaximumsOpen} tempid={id ?? ''} />
                  </Col>
                )))}
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
};

export default PatientDetails;
