import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap'
import Chart from 'react-google-charts'
import webservice from "../../../../Service/webservice";
import websocket_webservice from "../../../../Service/websocket_webservice";
import { AesDecrypt, AesEncrypt } from '../../../../Service/crypto';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Select, { SingleValue } from "react-select";
import { get_tat } from '../store/index';
import { H2, H3 } from '../../../../AbstractElements';
import classnames from "classnames";

interface IClientOption {
    label: string;
    value: string;
}

interface CompanyOption {
    label: string;
    value: string;
}

interface Count {
    client_id: string;
    count: number;
}

interface File {
    uploaded_date?: string;
    ocr_completed_date?: string;
    Completed_date?: string;
    approved_date?: string;
    review_date?: string;
    Error_date?: string;
    Data_Fetched?: string;
    data_extraction?: string;
    client_id?: string;
    client_name?: string;
    id?: string;
    ocr_status?: string;
    xml_conversion?: string;
    uploaded_status?: string;
    RegenrateId?: string;
    download_status?: string;
}

const TAT = () => {

    const { control } = useForm();
    const dispatch = useDispatch();
    const [selectedClient, setSelectedClient] = useState<string>('All');
    const [selectedClientId, setSelectedClientId] = useState<string>('ALL');
    const [clientOptions, setClientOptions] = useState<IClientOption[]>([]);
    const [completedFilesCount, setCompletedFilesCount] = useState<number[]>([]);
    const [totalFilesCount, setTotalFilesCount] = useState<number[]>([]);
    // const [regenrateFilesCount, setRegenratedFilesCount] = useState<number>(0);
    // const [downloadFilesCount, setDownloadFilesCount] = useState<number>(0);
    // const [inprogressFilesCount, setinprogressdata] = useState<number[]>([]);
    // const [errorFilesCount, seterrorCount] = useState<number[]>([]);


    const UserType = localStorage.UserType;
    const Id = localStorage.VendorId;
    const get_companyname = async () => {
        // Prepare the request body based on UserType
        let bodyData = {};
        if (UserType === 'MOS') {
            bodyData = { UserType }; // Send UserType only
        } else if (UserType === 'Vendor') {
            bodyData = { UserType, VendorId: Id }; // Send both UserType and VendorId
        }


        // Make the fetch request
        const response = await fetch(webservice + 'api/Dashboard/getclient', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(bodyData), // Send the request body
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };


    const ViewAuditLog = async () => {
        const Username = localStorage.UserName;
        const Userid = localStorage.id;
        const Clientname = selectedClient;
        const Chartname = 'TAT'
        const Data = { Username, Clientname, Userid, Chartname };

        
        const EncParams = AesEncrypt(JSON.stringify(Data));
        const response = await fetch(webservice + 'api/Dashboard/auditlog', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then((response) => response.json());
        return response;
    };

    useEffect(() => {
        const fetchRoleId = async () => {
            try {
                const response = await get_companyname();
                const companyData = response.map((data: any) => ({
                    label: data.CompanyName,
                    value: data.id,
                }));

                // Sort the data alphabetically by label
                const sortedCompanyData = companyData.slice().sort((a: CompanyOption, b: CompanyOption) => a.label.localeCompare(b.label));
                // Add the 'All' option at the beginning of the list
                const allOption = { label: 'All', value: 'ALL' };
                const companyDataWithAll = [allOption, ...sortedCompanyData];

                // Set the client options state
                setClientOptions(companyDataWithAll);
            } catch (error) {
                console.error(error);
            }
        };

        // Fetch the initial data
        fetchRoleId();
     
        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            fetchRoleId();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, []);


    const handleSelectClientChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setSelectedClient(selectedOption.label);
            setSelectedClientId(selectedOption.value);
            // template()
        } else {
            setSelectedClient('');
            setSelectedClientId('ALL');
        }
    }
    const [Count, setCount] = useState<Record<string, number>>({});

    useEffect(() => {
        let clientid: string | null;

        if (localStorage.UserType === 'MOS') {
            clientid = selectedClientId;
        } else if (localStorage.UserType === 'Vendor') {
            clientid = selectedClientId;
        } else {
            clientid = localStorage.ClientId;
        }

        const fetchData = async () => {
            try {
                const resReceived = await dispatch<any>(get_tat(clientid));
                const dataArrayReceived: File[] = resReceived.payload;
   
                
                const currentDate = new Date();

                // Group client IDs by difference in days from the uploaded date
                const dayRangesCount = dataArrayReceived.reduce((acc: Record<string, number>, item) => {
                    if (item.uploaded_date) {
                        const uploadedDate = new Date(item.uploaded_date); // Convert to Date object
                        const diffTime = Math.abs(currentDate.getTime() - uploadedDate.getTime());
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert to days

                        let dayRange = '';
                        if (diffDays === 1) dayRange = "1 day";
                        else if (diffDays === 2) dayRange = "2 day";
                        else if (diffDays === 3) dayRange = "3 day";
                        else if (diffDays === 4) dayRange = "4 day";
                        else if (diffDays === 5) dayRange = "5 day";
                        else if (diffDays === 6) dayRange = "6 day";
                        else if (diffDays >= 7) dayRange = "7+ days";

                        // Count client IDs for each day range
                        acc[dayRange] = (acc[dayRange] || 0) + 1;
                    }
                    return acc;
                }, {});

                // Update state with the calculated day ranges count
                setCount(dayRangesCount);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        ViewAuditLog()

        const socket = new WebSocket(`${websocket_webservice}ws/Dashboardpatient/`);
        // socket.onopen = () => console.log("WebSocket connected");
        // socket.onclose = () => console.log("WebSocket closed");
        // socket.onerror = (error) => console.error("WebSocket error:", error);

        socket.onmessage = () => {
            fetchData();
        };

        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, [dispatch, selectedClientId, selectedClient]);

    // Specify the desired day range order
    const orderedDayRanges = ["1 day", "2 day", "3 day", "4 day", "5 day", "6 day", "7+ days"];

    // Create data array for Google Chart based on the Count state and ordered day ranges
    const chartData: (string | number)[][] = [["Day", "Count"]];
    orderedDayRanges.forEach(dayRange => {
        if (Count[dayRange]) {
            chartData.push([dayRange, Count[dayRange]]); // Add only if the day range exists in Count
        } else {
            chartData.push([dayRange, 0]); // If no data for this day range, add 0
        }
    });

    // const chartData: (string | number)[][] = [["Day", "Count"]];
    // for (const [dayRange, count] of Object.entries(Count)) {
    //     chartData.push([dayRange, count]); // Use actual count as a number
    // }
    // Check if all counts are 0
    const allCountsZero = chartData.slice(1).every(([label, count]) => count === 0);

    const googleChartDatas = {
        GoogleDonutChart: {
            width: "100%",
            height: "400px",
            chartType: "PieChart",
            data: allCountsZero
                ? [
                    ['Label', 'Value'],
                    ['No Data', 1], // Fallback data
                ]
                : chartData, // Use the actual chart data if not all counts are 0
            options: {
                // title: "Client not upload any Files",
                colors: allCountsZero
                    ? ['#ccc'] // Color for "No Data"
                    : ["#993399", "#0000FF", "#FDD835", "#9400D3", "#4B0082", "#00FF00", "#FF7F00",'#FF0000'],
                pieHole: 0.4, // Donut chart setting
            },
        },
    };

    

    return (

        <Card>
            <CardHeader>
                <div className="header-top">
                    <H2>TAT</H2>
                    <Col xl={6}>
                        {(localStorage.UserType === 'MOS' || localStorage.UserType === 'Vendor') && (
                            <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                                <Label className="form-label" style={{ fontSize: "110%" }}>
                                    Select Client
                                </Label>
                                <Controller
                                    name="client"
                                    control={control} // control comes from react-hook-form
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            isClearable
                                            options={clientOptions}
                                            classNamePrefix="select"
                                            placeholder="Client Names"
                                            className={classnames("form-control")}
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    width: "100%",
                                                    height: '100%',
                                                    borderColor: "black",
                                                }),
                                                placeholder: (styles) => ({
                                                    ...styles,
                                                    textAlign: "center",
                                                }),
                                            }}
                                            value={selectedClient ? { value: selectedClientId, label: selectedClient } : null}
                                            onChange={(selectedOption) => {
                                                handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                field.onChange(selectedOption); // Update the field's value
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        )}

                    </Col>
                </div>
            </CardHeader>
            <CardBody className="chart-block">
                <div className="chart-overflow" id="pie-chart1">
                    <Chart
                        width={"100%"}
                        height={"400px"}
                        chartType={"PieChart"}
                        loader={<div>{"Loading Chart"}</div>}
                        data={googleChartDatas.GoogleDonutChart.data}
                        options={googleChartDatas.GoogleDonutChart.options}
                    />
                </div>
            </CardBody>
        </Card>

    )
}

export default TAT