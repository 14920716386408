import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row, Table } from "reactstrap";
import { H2, H3 } from "../../../AbstractElements";
import { H4 } from "../../../AbstractElements";
import { useEffect, useState } from "react";
import { AesDecrypt, AesEncrypt } from "../../../Service/crypto";
import webservice from '../../../Service/webservice';
import Select from 'react-select';
const recentOrdersHeader = [
  "Patient Name", "Payer", "Date", "Errors"
]

interface VendorOption {
  label: string;
  value: string;
  key: string;
}

const RecentOrders = () => {

  const [adminoption, setVendor] = useState<VendorOption[]>([]);
  const [recentOrdersBody, setrecentOrdersBody] = useState<[]>([]);
  const [VendorName, setVendorName] = useState<string>('');
  const [VendorId, setVendorId] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState(null); // State to track selected patient

  useEffect(() => {
    if (localStorage.UserType === 'Vendor') {
      setVendorId(localStorage.id)
    }
  })

  useEffect(() => {
    const fetchRole = async (Id: any) => {
      const EncParams = AesEncrypt(Id);
      const response = await fetch(webservice + 'api/Dashboard/Vendorlog', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then((response) => response.json());
      const TempData = AesDecrypt(response);
      setrecentOrdersBody(TempData)
      return TempData;
    };

    fetchRole(VendorId);

  }, [VendorId]);

  const get_VendorRoleId = async () => {
    const response = await fetch(webservice + 'api/VendorManagement/get_vendor', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => response.json());
    const TempData = AesDecrypt(response);
    return TempData;
  };

  useEffect(() => {
    const fetchVendor = async () => {
      const response = await get_VendorRoleId();
      const res = response.map((data: any) => ({
        label: data.CompanyName,
        value: data.CompanyName,
        key: data._id,
      }));
      const allOption: VendorOption = {
        label: "All",
        value: "All",
        key: '',
      };
      setVendor([allOption, ...res]);
    };
    fetchVendor();
  }, []);

  const handleVendorChange = async (selectedOption: any) => {
    if (selectedOption) {
      setSelectedClient(null)
      setVendorName(selectedOption.label);
      setVendorId(selectedOption.key);
    } else {
      setVendorName('');
      setVendorId('');
    }
  }

  const patientOptions = recentOrdersBody
    .map((data: any) => ({ value: data.ClientName, label: data.ClientName }))
    .filter((option, index, self) => self.findIndex(o => o.value === option.value) === index);

  const handlePatientChange = (option: any) => {
    setSelectedClient(option ? option.value : null);
  };

  const filteredOrders = selectedClient
    ? recentOrdersBody.filter((order: any) => order.ClientName === selectedClient)
    : recentOrdersBody;

  return (
    <Card className="invoice-card" style={{ height: '500px' }}>
      {localStorage.UserType === 'MOS' && (
        <CardHeader className="pb-0">
          <Row>
            <Col xl="2"> <H2>Vendor Log</H2></Col>
            <Col xl="8"></Col>
            <Col xl="2">
              <Label check>Vendor<span style={{ color: 'red' }}> * </span></Label>
              <Select
                name="Vendor"
                options={adminoption}
                onChange={(option: any) => { handleVendorChange(option); }}
                className="react-select"
                classNamePrefix="select"
              />
            </Col>
          </Row>
        </CardHeader>)}
      <CardBody className="transaction-card">
        {VendorId !== '' && (
          <H3>{VendorName} :-</H3>
        )}
        <div className="table-responsive theme-scrollbar recent-wrapper">
          <Table className="display order-wrapper" id="recent-order">
            <thead >
              <tr>
                <th >
                  <Select
                    name="Client"
                    options={patientOptions}
                    onChange={handlePatientChange}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: '250px',
                      }),
                      menu: (base) => ({
                        ...base,
                        width: '250px',
                      }),
                      menuList: (base) => ({
                        ...base,
                        maxHeight: '150px',
                        maxWidth: '250px',
                      }),
                      option: (base, state) => ({
                        ...base,
                        height: '30px',
                        padding: '5px',
                      }),
                    }}
                    className="react-select"
                    classNamePrefix="select"
                    placeholder="Client Name"
                    isClearable // Allow clearing the selection
                  /></th>
                <th >Patient Name</th>
                <th >Status</th>
              </tr>
            </thead>
            <tbody >
              {filteredOrders.length > 0 ? (
                filteredOrders.map((data: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex">
                        <div className="flex-grow-1 ms-2">
                          <H4>{data.ClientName}</H4>
                        </div>
                      </div>
                    </td>
                    <td >{data.Patientname}</td>
                    <td >{data.Data_Fetched}</td>
                  </tr>
                ))) : (
                <tr>
                  <td colSpan={recentOrdersHeader.length} style={{ textAlign: 'center' }}>
                    No records to display
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
      <CardFooter>
        <Row className="mt-3">
          <Col xl="8"></Col> {/* Empty space to push counters to the right */}
          <Col xl="1" className="text-center">
            <div className="dashboard-stat">
              <h4>Completed</h4>
              <p>{filteredOrders.filter((order: any) => order.Data_Fetched === 'Completed').length}</p>
            </div>
          </Col>
          <Col xl="1" className="text-center">
            <div className="dashboard-stat">
              <h4>In Progress</h4>
              <p>{filteredOrders.filter((order: any) => order.Data_Fetched === 'In progress').length}</p>
            </div>
          </Col>
          <Col xl="1" className="text-center">
            <div className="dashboard-stat">
              <h4>Error</h4>
              <p>{filteredOrders.filter((order: any) => order.Data_Fetched === 'Error').length}</p>
            </div>
          </Col>
          <Col xl="1" className="text-center">
            <div className="dashboard-stat">
              <h4>Total</h4>
              <p>{filteredOrders.length}</p>
            </div>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default RecentOrders;
