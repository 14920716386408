import React, { useRef, useState, useEffect } from 'react';
import { Card, Col } from "reactstrap";
import webservice from "../../../../../Service/webservice";
import ProfileDetail from "./ProfileDetail";
import { AesEncrypt, AesDecrypt } from '../../../../../Service/crypto';

import A from '../../../../../assets/Avatar/a.png';
import B from '../../../../../assets/Avatar/b.png';
import C from '../../../../../assets/Avatar/c.png';
import D from '../../../../../assets/Avatar/d.png';
import E from '../../../../../assets/Avatar/e.png';
import F from '../../../../../assets/Avatar/f.png';
import G from '../../../../../assets/Avatar/g.png';
import H from '../../../../../assets/Avatar/h.png';
import I from '../../../../../assets/Avatar/i.png';
import J from '../../../../../assets/Avatar/j.png';
import K from '../../../../../assets/Avatar/k.png';
import L from '../../../../../assets/Avatar/l.png';
import M from '../../../../../assets/Avatar/m.png';
import N from '../../../../../assets/Avatar/n.png';
import O from '../../../../../assets/Avatar/o.png';
import P from '../../../../../assets/Avatar/p.png';
import Q from '../../../../../assets/Avatar/q.png';
import R from '../../../../../assets/Avatar/r.png';
import S from '../../../../../assets/Avatar/s.png';
import T from '../../../../../assets/Avatar/t.png';
import U from '../../../../../assets/Avatar/u.png';
import V from '../../../../../assets/Avatar/v.png';
import W from '../../../../../assets/Avatar/w.png';
import X from '../../../../../assets/Avatar/x.png';
import Y from '../../../../../assets/Avatar/y.png';
import Z from '../../../../../assets/Avatar/z.png';
import { Edit2 } from 'react-feather';
import Swal from 'sweetalert2';

const avatarImages: { [key: string]: string } = {
  A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, T, U, V, W, X, Y, Z
};

const Profile = () => {
  const [avatar, setAvatar] = useState('');
  const UserId = localStorage.id;
  const Profile = localStorage.Avatar;
  const Username = localStorage.UserName;

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (Profile) {
      setAvatar(Profile);
    } else if (Username) {
      const firstLetter = Username.charAt(0).toUpperCase();
      if (avatarImages[firstLetter]) {
        setAvatar(avatarImages[firstLetter]);
      }
    }
  }, [Profile, Username]);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    console.log(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;
        const ProfileDate = { UserId, base64String: base64String.split(',')[1] };
        const EncParams = AesEncrypt(JSON.stringify(ProfileDate));
        try {
          const response = await fetch(webservice + 'api/profile', {
            method: 'POST',
            body: JSON.stringify(EncParams),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          });
          //         const result = await response.json();
          //         const Decrypt = AesDecrypt(result);
          //         console.log(Decrypt);

          //         localStorage.setItem("Avatar", Decrypt.ProfileImg);
          //         setAvatar(Decrypt.ProfileImg);
          //       } catch (error) {
          //         console.error('Error fetching audit log data:', error);
          //       }
          //     };
          //     reader.readAsDataURL(file);
          //   }
          // };
          if (response.ok) {
            const result = await response.json();
            const Decrypt = AesDecrypt(result);
            localStorage.setItem("Avatar", Decrypt.ProfileImg);
            setAvatar(Decrypt.ProfileImg);

            Swal.fire({
              title: 'Insurance Verification Platform',
              icon: 'success',
              text: 'Profile updated successfully!',
              confirmButtonText: 'OK',
            });
          } else {
            Swal.fire({
              title: 'Insurance Verification Platform',
              icon: 'warning',
              text: 'File size exceeds the maximum limit',
              confirmButtonText: 'OK',
            });
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Col sm={12}>
      <Card className="hovercard text-center">
        <Col></Col>
        <br />
        <div className="user-image">
          <div className="avatar">
            <img className='step1' src={avatar} style={{ height: "155px", width: "155px" }} alt="Profile Avatar" />
          </div>
          <div className="icon-wrapper" style={{ cursor: "pointer" }} onClick={handleIconClick}>
            <Edit2 />
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
        </div>
        <ProfileDetail />
      </Card>
    </Col>
  );
};

export default Profile;
