import { Card, Col, Container, Input, InputGroup, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Button, CardBody } from 'reactstrap';
import { useEffect, useState } from 'react';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from "../../../Service/crypto";
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "react-feather";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Get_permission, getData } from "./store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../ReaduxToolkit/Hooks";
import ReactPaginate from "react-paginate";



const CustomHeader = ({ menuActions, handleFilter, value, handlePerPage, rowsPerPage }: any) => {
  return (
    <div className='client-master-list-table-header w-100 py-2'>
      <Row>
        <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
          <div className='d-flex align-items-center me-4 '>
            <label htmlFor='rows-per-page' className='me-2'>Rows per Page:</label>
            <Input
              type='select'
              id='rows-per-page'
              value={rowsPerPage}
              onChange={handlePerPage}
              className='form-control ms-50 larger-select '
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
            </Input>
          </div>
        </Col>
        <Col
          lg='6'
          className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-3'
        >
          <InputGroup className="input-group-search mb-2 zoom-on-hover">
            <Input
              type="text"
              placeholder="🔍 Search...."
              value={value}
              onChange={(e) => handleFilter(e.target.value)}
              className="input-search"
            />
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};

const Loginhistory = () => {



  interface DeleteRowData {
    id: string;
    UserName: string;
    Action: string;
    LocationIP: string;
    DateTime: string;
    Browser: string;
    OS: string;
    NetSpeed: string;
  }

  const [data, setData] = useState<DeleteRowData[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState(true);


  const dispatch = useAppDispatch()
  const store = useSelector((state: any) => state.History)
  const [value, setValue] = useState<string>('');
  const [sort, setSort] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<any>('Date/Time'); // Example initial sort column
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [menuActions, setMenuActions] = useState<string>('');
  const [UserType, setUserType] = useState<string>(localStorage.UserType);

  useEffect(() => {
    if (localStorage.id !== null) {
      dispatch(Get_permission(localStorage.id)).then((response) => {
        const res1 = response.payload;
        const Action = res1[0].Permission.filter(
          (item: any) => item.MenuKey === "USER_LOGIN_HISTORY"
        );
        setMenuActions(Action[0].MenuActions);
      });
    }
  }, [localStorage.id])


  useEffect(() => {
    dispatch(
      getData({
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn
      })
    );
  }, [dispatch, store.data.length, value, rowsPerPage, currentPage, sortColumn]);

  const handleReloadTable = () => {
    dispatch(
      getData({
        UserType,
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn
      })
    );
  };


  const handleFilter = (val: any) => {
    setValue(val)
    dispatch(
      getData({
        UserType,
        q: val,
        perPage: rowsPerPage,
        page: currentPage,
        sort,
        sortColumn,
      })
    )
  }

  const handlePerPage = (e: any) => {
    setCurrentPage(1)
    dispatch(
      getData({
        UserType,
        q: value,
        perPage: parseInt(e.target.value),
        page: currentPage,
        sort,
        sortColumn,
      })
    )
    setRowsPerPage(parseInt(e.target.value))
  }



  const handlePagination = (page: any) => {
    dispatch(
      getData({
        UserType,
        q: value,
        perPage: rowsPerPage,
        page: page.selected + 1,
        sort,
        sortColumn,
      })
    )
    setCurrentPage(page.selected + 1)
  }


  const dataToRender = () => {
    const filters: any = {
      q: value

    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (store.data.length > 0) {
      return store.data
    } else if (store.data.length === 0 && isFiltered) {
      return []
    } else {
      return store.allData.slice(0, rowsPerPage)
    }
  }

  const handleSort = (column: any, sortDirection: any) => {
    setSort(sortDirection)
    setSortColumn(column.sortField)
    dispatch(
      getData({
        UserType,
        q: value,
        perPage: rowsPerPage,
        page: currentPage,
        sort: sortDirection,
        sortColumn: column.sortField
      })
    )
  }

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.total / rowsPerPage))

    return (
      <ReactPaginate
        previousLabel={<ChevronLeft size={16} />}
        nextLabel={<ChevronRight size={16} />}
        breakLabel="..."
        pageCount={count || 1}
        onPageChange={handlePagination}
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        containerClassName="pagination-container"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
      />
    )
  }
  const timezone = moment.tz.guess();


  const Columns = [
    {
      name: "User Name",
      selector: (row: DeleteRowData) => row.UserName,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row: DeleteRowData) => row.Action,
      sortable: true,
    },
    {
      name: "Location IP",
      selector: (row: DeleteRowData) => row.LocationIP,
      sortable: true,
    },
    {
      name: "Date and Time",
      // selector: (row: DeleteRowData) => row.DateTime,
      selector: (row: any) => moment.utc(row.DateTime).tz(timezone).format('MM/DD/YYYY hh:mm:ss A'),

      sortable: true,
    },
    {
      name: "Browser",
      selector: (row: DeleteRowData) => row.Browser
      ,
      sortable: true,
    },
    {
      name: "OS",
      selector: (row: DeleteRowData) => row.OS
      ,
      sortable: true,
    },
    {
      name: "Network Speed",
      selector: (row: DeleteRowData) => row.NetSpeed
      ,
      sortable: true,
    },

  ];

  const customStyles = {
    rows: {
      style: {
        '&:hover': {
          backgroundColor: '#f0f0f0',

        },
      },
    },
    headCells: {
      style: {
        backgroundColor: '#355876',  // Change background color of headers
        color: 'white',  // Change text color of headers
        fontSize: '16px',  // Adjust font size of headers
      },
    },
  };

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(currentPage * rowsPerPage, store.total);
  const CustomLoadingComponent = () => {
    return (
      <div className="">
        {store.no_Data === 'There is no Record Found' ? (
          <h4>
            <br/>
            <p>There are no records to display</p>
          </h4>
        ) : (
          <div className="custom-loader">
            <label className="custom-label">Please wait...</label>
            <div className="custom-loading"></div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Breadcrumbs mainTitle='User Login History' title='User Login History' />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card >
              <CardBody>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <DataTable
                    persistTableHead
                      subHeader={true}
                      responsive={true}
                      onSort={handleSort}
                      data={dataToRender()}
                      columns={Columns}
                      paginationServer
                      customStyles={customStyles}
                      paginationPerPage={rowsPerPage}
                      paginationDefaultPage={currentPage}
                      selectableRows
                      selectableRowsHighlight
                      // selectableRowsComponent={BootstrapCheckbox as unknown as React.ReactNode}
                      // onSelectedRowsChange={handleChangecheck}
                      paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                      noDataComponent={<CustomLoadingComponent />}
                      paginationComponent={CustomPagination}
                      subHeaderComponent={
                        <CustomHeader
                          value={value}
                          menuActions={menuActions}
                          rowsPerPage={rowsPerPage}
                          handleFilter={handleFilter}
                          handlePerPage={handlePerPage}

                        />
                      }
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ paddingLeft: '2%' }}>
                        {store.total && (
                          <span><strong>{`${startIndex} to ${endIndex} of ${store.total} entries`}</strong></span>
                        )}
                      </div>
                      <div>
                        <CustomPagination />
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Loginhistory;
