import { configureStore } from "@reduxjs/toolkit";
import LayoutSlice from "./Reducer/LayoutSlice";
import ThemeCustomizerSlice from "./Reducer/ThemeCustomizerSlice";
import FilterSlice from "./Reducer/FilterSlice";
import ProductSlice from "./Reducer/ProductSlice";
import AppMenuSlice from '../Pages/Apps/AppMenu/store';
import UserManagementSlice from '../Pages/Apps/UserManagement/store';
import UserTypeSlice from '../Pages/Apps/UserType/store';
import { clientManagementReducer, clientAdminReducer } from '../Pages/Apps/ClientManagement/store';
import { vendorManagementReducer,teamReducer, vendorAdminReducer } from '../Pages/Apps/Vendor/store';
import { bulkUploadReducer,manualEntriesReducer ,patientReducer} from '../Pages/Apps/BulkUpload/store';
import PayerSlice  from "../Pages/Apps/Payor/store";
import PatientSlice  from "../Pages/Apps/Patients/store";
import  TemplateSlice  from "../Pages/Apps/TemplateCreation/store";
// import ToDoSlice from "./Reducer/ToDoSlice";
import ChatSlice from "./Reducer/ChatSlice";
import  InsuranceSlice  from "../Pages/Apps/Insurance/store";
import HistorySlice from "../Pages/Apps/Userloginhistory/store";
import ActivelistSlice from "../Pages/Apps/Activeruserlist/store";
import  OnderSlice  from "../Pages/Apps/Onderful_api/store";
const Store = configureStore({
reducer:{
    layout:LayoutSlice,
    themeCustomizer: ThemeCustomizerSlice,
    filterData: FilterSlice,
    product: ProductSlice,
    AppMenu: AppMenuSlice,
    UserManagement: UserManagementSlice,
    UserType: UserTypeSlice,
    ClientManagement: clientManagementReducer,
    ClientAdmin: clientAdminReducer,
    VendorManagement: vendorManagementReducer,
    VendorAdmin: vendorAdminReducer,
    Team: teamReducer,
    BulkUpload: bulkUploadReducer,
    ManualEntries: manualEntriesReducer,
    Patients: patientReducer,
    Payer:PayerSlice,
    Patient:PatientSlice,
    Template:TemplateSlice,
    chatData: ChatSlice,
    Insurance: InsuranceSlice,
    History: HistorySlice,
    Activelist: ActivelistSlice,
    Onder:OnderSlice
}
});

export default Store;

export type RootState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch